import React from "react";
import DatePicker from "react-datepicker";
import Required from "../../../components/Required";
import Checkbox from "../../../components/Checkbox";
import Popover from "../../../../formik/refactorOut/components/Popper/Popover";
import { popoverLocation } from "../../../../config/Configuration";
import MaskedInput from 'react-text-mask';
import calendar from '../styles/assets/calendar.png'
import infoCircle from '../styles/assets/info-circle.png'
import BaptistFacilityInfo from '../orgs/7291/facilityInfo'
import HSSDocField from '../orgs/7278/HSSDocField'


const InformationToRelease = ({ formik, inputClasses, template, config }) => {

  const orgId = config.context.organization;
  const baptistId = '1007291';

  return (
    config.page.form.sections.informationToReleaseSection.active ?
      <div id='infoToReleaseSection' className='form-section'>

        <h2
          style={template.styles.section}
          className="section-heading">
          {template.title}
        </h2>

        <div className='form-fields'>
          <label className='section-instruction'
            style={template.styles.label}
          >
            {template.fields.infoToReleaseGroup.label}{" "}
            <Required value={true} />
          </label>

          {orgId == '1007393' && <i style={{width: '100%', marginTop: 10, marginBottom: 20}}>If you are requesting scanned hospital records please notate in the “Other” section below</i>}          
          <div style={{ padding: "0 0 10px 0" }} className="check-option-container">

            {template.fields.infoToReleaseGroup.entries.slice(0, template.fields.infoToReleaseGroup.entries.length).map(item => (
              <div key={item.value} className='check-option'>
                {/* {console.log(item)} */}
                <Checkbox style={{...template.styles.list, display: (item.key === 'otherItem') ? 'none' : 'flex'}} className="custom-form-check checkbox" key={item.value} name="infoToReleaseGroup" label={item.value} value={item.key}/>
                {" "}

                {
                  item.key == "otherItem" &&
                  <>
                    {/* <div>
                      <div style={{}}>                     */}
                    {/* <div style={{ marginTop: 20}}></div> */}
                    <input
                      {...formik.getFieldProps("infoToReleaseGroupOther")}
                      type="text"
                      maxLength={
                        template.fields.infoToReleaseGroupOther.validations.maxLength.value
                      }
                      name="infoToReleaseGroupOther"
                      id="infoToReleaseGroupOther"
                      className="form-control"
                      placeholder={template.fields.infoToReleaseGroupOther.hint}
                    />
                    {formik.touched.infoToReleaseGroupOther &&
                      formik.errors.infoToReleaseGroupOther ? (
                      <span className="input-error-text">
                        {formik.errors.infoToReleaseGroupOther}
                      </span>
                    ) : null}
                    {/* </div>
                  </div> */}

                  </>
                }
                <Popover placement={popoverLocation}>
                  <Popover.Target>
                    {/* <i className="fal fa-info-circle fa_custom info-tip"></i> */}
                    <img className='info-circle' src={infoCircle}></img>
                  </Popover.Target>
                  <Popover.Content>{item.tooltip}</Popover.Content>
                </Popover>
              </div>
            ))}
            {orgId === '1007278' && <HSSDocField template={template} formik={formik}/>}
          </div>


          {/* <div style={{ padding: "0 0 10px 0", marginRight: "10%", "marginLeft": "23%" }} className="col-auto">
            {template.fields.infoToReleaseGroup.entries.slice(template.fields.infoToReleaseGroup.entries.length/2, template.fields.infoToReleaseGroup.entries.length).map(item => (
              <div key={item.value}>
                <Checkbox
                  style={template.styles.list}
                  className="custom-form-check"
                  key={item.value}
                  name="infoToReleaseGroup"
                  label={item.value}
                  value={item.key}
                />{" "}
                <Popover placement={popoverLocation}>
                  <Popover.Target>
                  <i className="fa fa-info-circle fa_custom"></i>
                  </Popover.Target>
                  <Popover.Content>{item.tooltip}</Popover.Content>
                </Popover>
                {
                  item.key == "otherItem" &&
                  <div className="form-group col-auto">
                    <div style={{ marginTop: 20 }}></div>
                    <input
                      {...formik.getFieldProps("infoToReleaseGroupOther")}
                      type="text"
                      maxLength={
                        template.fields.infoToReleaseGroupOther.validations.maxLength.value
                      }
                      name="infoToReleaseGroupOther"
                      id="infoToReleaseGroupOther"
                      className="form-control"
                      placeholder={template.fields.infoToReleaseGroupOther.hint}
                    />
                    {formik.touched.infoToReleaseGroupOther &&
                    formik.errors.infoToReleaseGroupOther ? (
                      <span className="input-error-text">
                        {formik.errors.infoToReleaseGroupOther}
                      </span>
                    ) : null}
                </div>
                }
              </div>
            ))}
            </div> */}




          <div className="date-container">

            <div className="form-field release-date-field fromDate-field">
              <label htmlFor="releaseFromDate">
                <span style={template.styles.label}>
                  {template.fields.releaseFromDate.label}
                  <Required value={true} />
                </span>
                <Popover placement={popoverLocation}>
                  <Popover.Target>
                    {/* <i className="fa fa-info-circle fa_custom"></i> */}
                    <img className='info-circle' src={infoCircle}></img>
                  </Popover.Target>
                  <Popover.Content>
                    {template.fields.releaseFromDate.tooltip}
                  </Popover.Content>
                </Popover>

              </label>
              <div>
                <div className='date-input'>
                  <DatePicker
                    locale={template.locale}
                    autoComplete="off"
                    {...formik.getFieldProps("releaseFromDate")}
                    dateFormat="MM/dd/yyyy"
                    id='from-date-field'
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={formik.values.releaseFromDate}
                    className={`${inputClasses(
                      formik.touched.releaseFromDate &&
                      formik.errors.releaseFromDate
                    )} date-field`}
                    name="releaseFromDate"
                    onChange={date => {
                      formik.setFieldTouched("releaseFromDate", true, true)
                      formik.setFieldValue("releaseFromDate", date)
                    }}
                    onSelect={date => {
                      formik.setFieldTouched("releaseFromDate", true, true)
                      formik.setFieldValue("releaseFromDate", date)
                    }}
                    placeholderText={template.fields.releaseFromDate.hint}
                  />
                  <img className='calendar-icon from-calendar-icon' src={calendar} onClick={(e)=>{document.getElementById('from-date-field').focus()}}></img>
                </div>
                {formik.touched.releaseFromDate &&
                  formik.errors.releaseFromDate && (
                    <span className="input-error-text">
                      {formik.errors.releaseFromDate}
                    </span>
                  )}
              </div>
            </div>

            <div className="form-field release-date-field toDate-field">
              <label htmlFor="releaseToDate">
                <span style={template.styles.label}>
                  {template.fields.releaseToDate.label}
                  <Required value={true} />
                </span>
                <Popover placement={popoverLocation}>
                  <Popover.Target>
                    {/* <i className="fa fa-info-circle fa_custom"></i> */}
                    <img className='info-circle' src={infoCircle}></img>
                  </Popover.Target>
                  <Popover.Content>
                    {template.fields.releaseToDate.tooltip}
                  </Popover.Content>
                </Popover>

              </label>
              <div>
                <div className='date-input'>
                  <DatePicker
                    locale={template.locale}
                    autoComplete="off"
                    id='to-date-field'
                    {...formik.getFieldProps("releaseToDate")}
                    dateFormat="MM/dd/yyyy"
                    customInput={<MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />}
                    selected={formik.values.releaseToDate}
                    className={`${inputClasses(
                      formik.touched.releaseToDate && formik.errors.releaseToDate
                    )} date-field`}
                    name="releaseToDate"
                    onChange={date => { formik.setFieldTouched("releaseToDate", true, true); formik.setFieldValue("releaseToDate", date) }}
                    onSelect={date => { formik.setFieldTouched("releaseToDate", true, true); formik.setFieldValue("releaseToDate", date) }}
                    placeholderText={template.fields.releaseToDate.hint}
                  />
                  <img className='calendar-icon to-calendar-icon' src={calendar} onClick={(e)=>{document.getElementById('to-date-field').focus()}}></img>
                </div>
                {formik.touched.releaseToDate && formik.errors.releaseToDate && (
                  <span className="input-error-text">
                    {formik.errors.releaseToDate}
                  </span>
                )}
              </div>
            </div>
          </div>


          {(template.fields.infoToReleaseAbstracts)
            ?
            <div id='abstract-query'>
              <p style={template.styles.label}>For the following type of records, I am requesting only a Medical Record Abstract</p>
              <div id='abstract-query-checkOptions'>
                {template.fields.infoToReleaseAbstracts.entries.map((field) => {
                  return <Checkbox style={template.styles.list} className="custom-form-check checkbox" key={field.value} name="infoToReleaseGroup" label={field.value} value={field.key} />
                })}
              </div>
            </div>
            :
            <></>
          }



          <p style={{ ...template.styles.label, marginBottom: 30 }}>{template.fields.certBox.disclaimerText}</p>
          {
            template.fields.certBox.active &&
            template.fields.certBox.entries.map(
              x => {
                return <div id='certification-check-option' className='check-option' key={x.value} style={{display: orgId == '1007343' ? 'none' : 'block'}}>

                  <input
                    type="checkbox"
                    className='checkbox'
                    checked={formik.getFieldProps('certBox').value}
                    onChange={() => {
                      formik.setFieldTouched("certBox", true, true);
                      if (formik.getFieldProps('certBox').value === true) {
                        formik.setFieldValue('certBox', false)
                      } else {
                        formik.setFieldValue('certBox', true)
                      }

                    }} />
                  <label htmlFor="certBox" style={template.styles.paragraph}>{x.value}</label>

                  {x.tooltip != "" && <Popover placement={popoverLocation}>
                    <Popover.Target>
                      <i className="fa fa-info-circle fa_custom"></i>
                    </Popover.Target>
                    <Popover.Content>
                      {template.fields.certBox.tooltip}
                    </Popover.Content>
                  </Popover>
                  }
                  {orgId === '1007201' && <p style={{position: 'absolute'}}>* A certification fee may apply</p>}

                </div>
              }
            )
          }

          <p style={{ ...template.styles.paragraph, marginTop: 20 }}>{template.fields.certBox.noteText}</p>
          
          {orgId === baptistId && <BaptistFacilityInfo/>}





        </div>
      </div>
      :
      <></>
  );
};

export default InformationToRelease;
