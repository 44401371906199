import * as Yup from "yup";
import { dateIsBefore } from "./validationFunctions";

// NOTE; use formLevelValidations for validations that cross
// section boundaries and all included sections have an active status
// e.g. Information to Release 'From Date' must be after Patient Information 'Birth Date'

export const formLevelValidations = form => {
  const { patientInformationSection, informationToReleaseSection } = form.sections;

  // Information to Release 'From Date' must be after Patient Information 'Birth Date'
  if (patientInformationSection.active && informationToReleaseSection.active) {
    return Yup.object().shape({
      releaseFromDate: Yup.date().test(
        "dateIsAfter",
        form.validations.releaseStartAfterDateOfBirth.message,
        function(releaseDate) {
          const otherDate = this.parent[
            form.validations.releaseStartAfterDateOfBirth.ref
          ];
          if (releaseDate && otherDate) {
            return dateIsBefore(otherDate, releaseDate);
          }
        }
      )
    });
  }
};
