import * as Yup from "yup";

const isDeliveryRadioBoxActive = (fields) => {
  if(fields.deliveryMethodGroup.active){
    return {
      deliveryMethodGroup: Yup.string().required(
        () =>
          fields.deliveryMethodGroup.validations.required
            .value &&
          fields.deliveryMethodGroup.validations.required
            .message
      )
    }
  }

}

export const deliveryMethodValidations = section => {
  if (section.active) {
    if (section.active) {
      return Yup.object().shape(
        isDeliveryRadioBoxActive(section.fields)
      );
    }
  }
};
