import React, {useState} from 'react';

import Modal from "react-modal";
import axios from "axios";
import {apiHost} from "../../../../config/Constants";

//called when user fills out Phone & Email. It sends the org, phone input, and email input to the DB, which returns array of objects matching
export const getMatchingRequestor = (org, email, phone, setRequestors, showModal, setRequestor, formik) => {
  return async () => {
    
    await axios
      .post(`${apiHost}/api/requestorMatch`, 
        {
          org: org,
          email: email,
          phone: phone
        }
     , {headers: {'content-type': 'application/json'}})
      .then(res => {
        // console.log(res.data.Result, 'Matching Requestors')
        
        // if(Array.isArray(res.data.Result)){
        // console.log(res.data.Result)
        
        const matchingRequestors = res.data.Result;

        // const matchingRequestors = res.data.Result.filter((set)=>{
        //   return set.Phone === formik.values.discloseToPhone
        // })
        // }
        
        if(Array.isArray(res.data.Result)){
          console.log(`${res.data.Result.length} requestors found`, res.data.Result)
          // if(res.data.Result.length == 1){
          //   //set rep contact info the values from result
          //   console.log(res.data.Result)
          //   return setRequestor(res.data.Result[0].FirstName, res.data.Result[0].LastName, 
          //     res.data.Result[0].Email, res.data.Result[0].Phone, res.data.Result[0].Address1, res.data.Result[0].City, res.data.Result[0].ZipCode, res.data.Result[0].State, res.data.Result[0].RequestorName, res.data.Result[0].Address2);
            
          // }
          if(matchingRequestors.length >= 1){
            // launch modal
            // console.log("should launch modal")
            // console.log(matchingRequestors)
            // console.log(formik);
            setRequestors(matchingRequestors)
            showModal(true)
          }else{
            //do nothing user continues filling out app as normal
            return
          }

        }else{
          return;
        }
        
      })
      .catch(err => {
        if(err.response !== undefined)
          console.log(err.response)            
      });
  }
}

export const RequestorMatchModal = (closeModal, setShowRequestorModal, template, requestors, setRequestor, selectUser, setSelectUser, selectedItem, setSelectedItem, selectorMatch, config) => {
  
    // console.log(config, 'config');
    return(
      <Modal ariaHideApp={false} isOpen={closeModal} onRequestClose={() => {setShowRequestorModal(false);}} className='selector-modal' >
        <p id='popup-title' style={selectorMatch.header.styles}>{selectorMatch.title}</p>
        <div id='popup-field-set'>
              {requestors.map( x => {
                  // console.log(requestors.indexOf(x), 'requestor index')
                  console.log(x, 'requestor set')
                  return( 
                    <RequestorSet x={x} setSelectUser={setSelectUser} keyy={requestors.indexOf(x)} setSelectedItem={setSelectedItem} selectedItem={selectedItem} selectorMatch={selectorMatch} config={config}/>
                  )                            
                }) 
              } 
        
        </div>
          <div id='popup-footer'>
            <button id='popup-close-button' className="popup-button" style={config.page.form.styles.buttons.cancel} onClick={() =>{ setShowRequestorModal(false); }}> {selectorMatch.cancelButton.text} </button>  
            
            <button
              id='popup-select-button'
              className="popup-button"
              // style={selectorMatch.submitButton.styles}
              style={config.page.form.styles.buttons.act}
              onClick={() =>{
                if(selectUser !== undefined)
                  setRequestor(selectUser.firstName, selectUser.lastName, selectUser.email, selectUser.phone, selectUser.address, selectUser.city, selectUser.zipCode, selectUser.state, selectUser.orgName, selectUser.address2, selectUser.fax, selectUser.requestorNameId, selectUser.requestorContactId);
                setShowRequestorModal(false);
              }}
              >
             {selectorMatch.submitButton.text}
          </button>
  
        </div>
        {/* </div> */}
      </Modal>
    );
  
}
  
export const RequestorSet = ({x, setSelectUser, setSelectedItem, selectedItem, keyy, selectorMatch, config}) =>{
  // const [selected, setSelected] = useState(false)
  const selectorConfig = config.page.form.components.selectorMatch
  
  return(
      <div className='popup-requestor-set' 
        style={(selectedItem === keyy) ? {background: selectorConfig.fields.canvas.active.backgroundColor, color: 'white'}:{color: selectorConfig.fields.canvas.active.backgroundColor}}
        key={x.RequestorContactID} 
          onClick={() =>{
            setSelectUser({ firstName: x.FirstName, lastName: x.LastName, email: x.Email, phone: x.Phone, address: x.Address1, city: x.City, state: x.State, zipCode: x.ZipCode, orgName: x.RequestorName, address2: x.Address2, fax: x.Fax, requestorNameId: x.RequestorNameID, requestorContactId: x.RequestorContactID});
            setSelectedItem(keyy);
          }}
      >
                    
          <p className='field-datum requestor-name' style={{padding: 0}}>{`${x.FirstName} ${x.LastName}`}</p>
          <p className='field-datum org-name' style={{padding: 0}}>{x.RequestorName}</p>
        
        <div className='popup-minor-info-container'>
          <p className='field-datum popup-minor-info' style={{padding: 0}}>{x.Email}</p>
          <p className='field-datum popup-minor-info' style={{padding: 0}}>{x.Phone}</p>
          <p className='field-datum last-datum popup-minor-info' style={{padding: 0}}>{x.Fax}</p>
        </div>
        
        <div className='popup-minor-info-container'>
          <p className='field-datum popup-minor-info' style={{padding: 0}}>{x.Address1}</p>
          {(x.Address2) ? <p className='field-datum popup-minor-info' style={{padding: 0}}>{x.Address2}</p>:<></>}
          <p className='field-datum popup-minor-info' style={{padding: 0}}>{x.City}</p>
          <p className='field-datum popup-minor-info' style={{padding: 0}}>{x.State}</p>
          <p className='field-datum last-datum popup-minor-info' style={{padding: 0}}>{x.ZipCode}</p>
        </div>
                
      </div>
  )
}
  
  