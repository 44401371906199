import React, {useState, useEffect} from 'react';
import Modal from "react-modal";
import axios from "axios";
// import '../form/styles/css/summaryPage/summaryPage.css';
import '../form/styles/sass/summaryPage/summaryPage.scss';
import {getwayApi} from '../../../config/Constants'
import PBV from '../../assets/pbv.png'
import { setNestedObjectValues } from 'formik';
// import logo from './styles/assets/verisma_logo.png';
// import {
//     registerLocales as registerLocalesForDatePickers,
//     modalCustomStyles
//   } from "../../config/Configuration";


export const SummaryModal = ({config, userInput, submitData, isModelOpen, setSummary, logo, formId, formik}) => {
    const [closeModal, setCloseModal] = useState(isModelOpen)
    const [facValues, setFacValues] = useState(null)
    
    
    const formHasCustomFacilities = config.context.organization === '1007229' || config.context.organization === '1007342' || config.context.organization === '1007343' || config.context.organization === '1007307'
    const THRForm = config.context.organization === '1007451'
    
    useEffect(()=>{
        if (formik.values.infoToReleaseGroup.length === 0 && formik.values.infoToReleaseGroupOther.length !== 0) {
            formik.setFieldValue('infoToReleaseGroup', ['00000'])
        }
        return () => {
            formik.values.infoToReleaseGroup.includes('00000') && formik.setFieldValue('infoToReleaseGroup', [])
        } 
    })
    
    useEffect(()=>{
        !Array.isArray(formik.values.facilitiesGroup) &&
        formik.setFieldValue('facilitiesGroup', [userInput.facilitiesGroup]); 
        /* Called for forms w/ Radio Buttons for facilities as they add the facility to the formik object as a string, 
        rather than an array of strings (which pdf/vrm expects) */
    })

    useEffect(() => {
        const phoneValue = formik.values.discloseToPhone
        !phoneValue.includes('-') && formik.setFieldValue('discloseToPhone', `${phoneValue.slice(0, 3)}-${phoneValue.slice(3, 6)}-${phoneValue.slice(6, 10)}${phoneValue[10] ? phoneValue.slice(10, phoneValue.length) : ""}`)
    }, [formik.values.discloseToPhone])

    useEffect(()=>{
        if (formHasCustomFacilities) {
            const facGroup = formik.values.facilitiesGroup
            let originalFacilityGroupValue = facGroup
            
            
            return () => {
                formik.setFieldValue('facilitiesGroup', originalFacilityGroupValue)
            }
        }
    }, [])

    useEffect(()=>{
        if (formHasCustomFacilities) {
            delete formik.values.facilitySelected
            setFacValues(formik.values)
    
            return ()=>{
                formik.setFieldValue('facilitySelected', true)
            }
        }
    }, [formik.values.facilitySelected])

    
    useEffect(()=>{
        formik.setFieldValue('ReleaseMode', formik.values.deliveryMethodGroup)
    }, [formik.values.deliveryMethodGroup])

    console.log(formik.values)

    
    const facilitiesGroup = config.page.form.sections.authorizationToReleaseSection.fields.facilitiesGroup.entries;

    const facilityList = () => {
        const facilityFields = config.page.form.sections.authorizationToReleaseSection.fields

        if (formHasCustomFacilities && typeof userInput.facilitiesGroup !== 'string') {
            return (
                <>
                    <div>
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacName1}</p> 
                        {userInput.facilitiesGroup[0].customFacName1 && userInput.facilitiesGroup[0].customFacAddress1 && <p style={{display:'inline'}}>, </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacAddress1}</p> 
                        {userInput.facilitiesGroup[0].customFacAddress1 && userInput.facilitiesGroup[0].customFacDoctor1 && <p style={{display:'inline'}}>, </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacDoctor1}</p> 
                    </div>
                    <div>
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacName2}</p> 
                        {userInput.facilitiesGroup[0].customFacName2 && userInput.facilitiesGroup[0].customFacAddress2 && <p style={{display:'inline'}}> , </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacAddress2}</p>
                        {userInput.facilitiesGroup[0].customFacAddress2 && userInput.facilitiesGroup[0].customFacDoctor2 && <p style={{display:'inline'}}>, </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacDoctor2}</p>  
                    </div>
                    <div>
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacName3}</p> 
                        {userInput.facilitiesGroup[0].customFacName3 && userInput.facilitiesGroup[0].customFacAddress3 && <p style={{display:'inline'}}> , </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacAddress3}</p>
                        {userInput.facilitiesGroup[0].customFacAddress3 && userInput.facilitiesGroup[0].customFacDoctor3 && <p style={{display:'inline'}}>, </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacDoctor3}</p>  
                    </div>
                    <div>
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacName4}</p> 
                        {userInput.facilitiesGroup[0].customFacName4 && userInput.facilitiesGroup[0].customFacAddress4 && <p style={{display:'inline'}}> , </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacAddress4}</p>
                        {userInput.facilitiesGroup[0].customFacAddress4 && userInput.facilitiesGroup[0].customFacDoctor4 && <p style={{display:'inline'}}>, </p>} 
                        <p style={{display:'inline'}}>{userInput.facilitiesGroup[0].customFacDoctor4}</p>  
                    </div>
                </>
            )
        }
        
        if (typeof userInput.facilitiesGroup === 'string') {
            return <p>{userInput.facilitiesGroup}</p>
        }

        let outPatientFacilityList = [];
        const regFacilityList = userInput.facilitiesGroup.map((facility) => {
            if (typeof facility === 'object') {
                for (let property in facility) {
                    if (typeof property === 'string' && property.length > 0) {
                        outPatientFacilityList.push(<p>{facility[property]}</p>)
                    }
                }
            }
            else {
                let regFacilities = [];
                let outPatientFacilites = [];
                let longTermFacilites = [];
                
                regFacilities = facilityFields.facilitiesGroup.entries.map((entry)=>{
                    if(facility === entry.key) {return <p>{entry.value}</p>}
                })
                outPatientFacilites = facilityFields.outPatientGroup && facilityFields.outPatientGroup.entries.map((entry)=>{
                    if(facility === entry.key) {return <p>{entry.value}</p>}
                })
                longTermFacilites = facilityFields.longTermGroup && facilityFields.longTermGroup.entries.map((entry)=>{
                    if(facility === entry.key) {return <p>{entry.value}</p>}
                })
                // console.log(outPatientFacilites, longTermFacilites)
                return [...regFacilities, ...(outPatientFacilites || []), ...(longTermFacilites || [])]
            }
        })
        const fullFacilityList = regFacilityList.concat(outPatientFacilityList);
        // console.log(fullFacilityList, fullFacilityList.push(userInput.otherFac), userInput.otherFac)
        return fullFacilityList;
    }
    
    const ValidationErrorPopupBody = () => (
        <div id='summary-modal-sections'>

{/* ====================================================================================================================================================================================================== */}
            
            <div id='details-section' className='summary-section'>
                <div className='summary-section-title-div' style={{borderColor: config.page.form.components.summaryModal.section.styles.borderColor}}>
                    <p className='summary-section-title' style={config.page.form.components.summaryModal.section.styles}>{config.page.form.components.summaryModal.detailSummaryLabel}</p>
                </div>
                <div id='summary-section-details-field-div' className='summary-section-field-div'>
                    
                    

                    {(userInput.caseNumber)?<SummaryField config={config} label={config.page.form.sections.repContactInfoSection.fields.caseNumber.label} value={userInput.caseNumber} />:<></>}
                    
                    {/* {console.log(facilityList())} */}
                    <SummaryField config={config} label={config.page.form.sections.authorizationToReleaseSection.title} value={ facilityList() } other={userInput.otherFac.length > 0 ? true : false}/>
                    {userInput.otherFac.length > 0 && <p style={{marginBottom: 20}}>{userInput.otherFac}</p>}

                    <SummaryField config={config} label={config.page.form.sections.informationToReleaseSection.title} value={
                        (()=>{
                            let info = config.page.form.sections.informationToReleaseSection.fields.infoToReleaseGroup.entries.map( x => {
                                if(userInput.infoToReleaseGroup.includes(x.key) && x.key !== "otherItem"){
                                    
                                    return <p className='summary-field-value release-info-value' style={{marginBottom:0}}>{x.value} </p>
                                }
                                else if(userInput.infoToReleaseGroup.includes(x.key) && x.key === "otherItem"){
                                    
                                    return <p className='summary-field-value release-info-value' style={{marginBottom:0}}>{userInput.infoToReleaseGroupOther} </p>
                                }
                            })
                            let abstracts = (config.page.form.sections.informationToReleaseSection.fields.infoToReleaseAbstracts) ? 
                                config.page.form.sections.informationToReleaseSection.fields.infoToReleaseAbstracts.entries.map( x => {
                                    if(userInput.infoToReleaseGroup.includes(x.key) && x.key !== "otherItem"){
                                        return <p className='summary-field-value release-info-value' style={{marginBottom:0}}>{x.tooltip} </p>
                                    }
                                })
                                :
                                ''

                            
                            // if(userInput.certBox === true){
                            //     info.push(<p className='summary-field-value release-info-value' style={{marginBottom:0}}>{config.page.form.sections.informationToReleaseSection.fields.certBox.summaryText} </p>) 
                            // }
                            return(
                                <div id='release-info-value-container'>
                                    {info}
                                    {(config.page.form.sections.informationToReleaseSection.fields.infoToReleaseAbstracts) ? abstracts : <></>}
                                    {userInput.infoToReleaseGroupOther !== "" && <p>{userInput.infoToReleaseGroupOther}</p>}
                                    {userInput.releaseFromDate.toLocaleDateString( config.page.locale)} - {userInput.releaseToDate.toLocaleDateString(config.page.locale)}
                                    {(userInput.certBox === true) ? <p className='summary-field-value release-info-value' style={{fontWeight: 700, fontStyle:'italic'}}>{config.page.form.sections.informationToReleaseSection.fields.certBox.summaryText}</p>:<></>}
                                </div>
                            )
                        })()
                    }  />
                    
                    
                    {THRForm && <SummaryField config={config} label={config.page.form.sections.deliveryMethodSection.title} value={
                        userInput.ReleaseMode === '2' ? 'Email' : userInput.ReleaseMode === '5' ? 'Mail Paper Records' : 'CD/DVD'
                        // config.page.form.sections.deliveryMethodSection.fields.ReleaseMode.entries.map( x => {
                        //         return <p>{x.value}</p>
                        // })
                        // <p>userInput.ReleaseMode</p>
                    } />}
                    
                    <SummaryField config={config} label={config.page.form.sections.supportingDocsSection.title} value={userInput.supportingDoc.map( x => {
                                    return  <p>{x.name}</p>
                                })
                        } />

                    <SummaryField config={config} label={config.page.form.sections.additionalInfoSection.title} value={
                        config.page.form.sections.repContactInfoSection.fields.repRole.entries.map( x => {
                            if(userInput.repRole == x.value){
                                return <p>{userInput.repDesc}</p>
                            }
                        })
                    } classes={'bottom-summary-field'}/>
                </div>
            </div>

            <div id='upper-sections'>
                
                <div id='requestor-section' className='summary-section'>
                    <div className='summary-section-title-div' style={{borderColor: config.page.form.components.summaryModal.section.styles.borderColor}}>
                        <p className='summary-section-title' style={config.page.form.components.summaryModal.section.styles}>{config.page.form.sections.repContactInfoSection.title}</p>
                    </div>
                    <div className='summary-section-field-div'>
                        <SummaryField config={config} label={'NAME'} value={`${userInput.discloseToFName} ${userInput.discloseToMName} ${userInput.discloseToLName}`}/>
                        <SummaryField config={config} label={config.page.form.sections.repContactInfoSection.fields.discloseToEmail.label} value={userInput.discloseToEmail}/>
                        <SummaryField config={config} label={config.page.form.sections.repContactInfoSection.fields.discloseToPhone.label} value={userInput.discloseToPhone}/>
                        {(userInput.discloseToFax)?<SummaryField config={config} label={config.page.form.sections.repContactInfoSection.fields.discloseToFax.label} value={userInput.discloseToFax}/>:<></>}
                        <SummaryField config={config} label={config.page.form.sections.repContactInfoSection.fields.discloseToOrgName.label} value={userInput.discloseToOrgName}/>
                        <SummaryField config={config} label={config.page.form.sections.repContactInfoSection.fields.discloseToAddressLine1.label} value={`${userInput.discloseToAddressLine1} ${userInput.discloseToAddressLine2} ${userInput.discloseToCity} ${userInput.discloseToState} ${userInput.discloseToZip}`} />
                        <SummaryField config={config} label={config.page.form.components.summaryModal.reppedPartyLabel} value={
                            config.page.form.sections.repContactInfoSection.fields.repRole.entries.map( x => {
                                if(userInput.repRole == x.value){
                                    return ( <p>{x.label} </p> )
                                }
                            })
                    } classes={'bottom-summary-field'} />
                    </div>
                </div>
                

                <div id='patientInfo-section' className='summary-section'>
                    <div className='summary-section-title-div' style={{borderColor: config.page.form.components.summaryModal.section.styles.borderColor}}>
                        <p className='summary-section-title' style={config.page.form.components.summaryModal.section.styles}>{config.page.form.sections.patientInformationSection.title}</p>
                    </div>
                    <div className='summary-section-field-div'>
                        <SummaryField config={config} label={config.page.form.sections.discloseToSection.fields.discloseToName.label} value={`${userInput.patientInfoFirstName} ${userInput.patientInfoMiddleInitial} ${userInput.patientInfoLastName}`}/>
                        {(userInput.patientInfoOtherName) ? <SummaryField config={config} label={config.page.form.sections.patientInformationSection.fields.patientInfoOtherName.label} value={userInput.patientInfoOtherName}/> : <></>}
                        <SummaryField config={config} label={config.page.form.sections.patientInformationSection.fields.patientInfoDob.label} value={userInput.patientInfoDob.toLocaleDateString()} classes={'bottom-summary-field'}/>
                    </div>
                </div>
            </div>
        </div>
      );

      useEffect(()=>{
        window.onpopstate = () => setSummary(false)
    })
    
    // console.log(config.page.form.components.summaryModal.styles.logo, 'logo style');
    // console.log(config.page.form.components.summaryModal, 'summary Modal');
    // console.log(config.page.form.components.summaryModal.styles.logo.width, 'logo width');

      return(
        <Modal
          ariaHideApp={false}
          isOpen={closeModal}
          onRequestClose={() => {setCloseModal(false); setSummary(false)}}
        //   style={modalCustomStyles}
          className='summary-modal'
          style={{backgroundColor: config.page.form.components.summaryModal.styles.backgroundColor}}
        >
          <div id='summary-modal-content-container'>
         
            <img id='summary-modal-logo' style={config.page.form.styles.logos.summary} src={`${getwayApi}/api/client/logo/${formId}`} >
            
            </img>
            
              <h3 id="summary-modal-title" style={config.page.form.components.summaryModal.header.styles}>{config.page.form.components.summaryModal.header.text}
               
            {/* <button style={{float:"right"}}
                className="btn btn-outline-dark"
                //onClick={() => submitData(userInput)}
                onClick= {() => {setCloseModal(false); setSummary(false)}}
            >
                    <span aria-hidden="true">&times;</span>
                </button> */}
              </h3>
            
            {ValidationErrorPopupBody()}
            
            <div id='summary-submission-container'>
                <p id='summary-disclaimer' style={config.page.form.components.summaryModal.disclaimer.styles}>{config.page.form.components.summaryModal.disclaimer.text}</p>
                
                <div id='summary-buttons'>  
                    <button
                        id='summary-back-button'
                        className="summary-button"
                        style={config.page.form.styles.buttons.cancel}
                        onClick= {() => {setCloseModal(false); setSummary(false)}}
                        // onClick={() => submitData(userInput)}
                        >
                        {config.page.form.components.summaryModal.buttons.goBackLabel}
                    </button>

                    <button
                        id='summary-submit-button'
                        className="summary-button"
                        style={config.page.form.styles.buttons.act}
                        onClick={() => {
                            console.log(facValues); 
                            formHasCustomFacilities ? submitData(facValues) : submitData(userInput) 
                            console.log(userInput)}}
                        // onClick={() => {console.log(userInput)}}
                        >
                        {config.page.form.components.summaryModal.buttons.submitLabel}
                    </button>
                </div>
                <img src={PBV} className='pbv-image' alt="Powered by Verisma" />
            </div>
          </div>
        </Modal>
    );
}

const SummaryField = (props) => {
    return(
        <div className={`summary-field ${props.classes}`} 
        style={props.other ? {marginBottom: 0} : {}}
        >
            <p className='summary-field-label' style={props.config.page.form.components.summaryModal.label.styles}>{props.label}</p>
            <span className='summary-field-value' style={props.config.page.form.components.summaryModal.value.styles}>
                {props.value}
            </span>
        </div>
    )
}