import React from "react";
import Required from "../../../components/Required";
import Markdown from "markdown-to-jsx";
import { Field } from "formik";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";

const DiscloseTo = ({ formik, inputClasses, template, config }) => {
  const areFieldsDisabled = formik.values.discloseToEntityGroup === "namedPersonEntity" ? true : false;
  return (
    config.page.form.sections.discloseToSection.active ?
      <div>
        <div className="row">
          <div className="col">
            <h2 style={template.styles.section} className="section-heading">
              {template.title}
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <label>
                <span style={template.styles.label}>
                  {template.fields.discloseToEntityGroup.label}
                </span>{" "}
                <Required
                  value={
                    template.fields.discloseToEntityGroup.validations.required.value
                  }
                />
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {/* TODO; might need to be separate single radios so text can be inserted between */}
              <RadioButtonGroup
                id="discloseToEntityGroup"
                value={formik.values.discloseToEntityGroup}
                error={formik.errors.discloseToEntityGroup}
                touched={formik.touched.discloseToEntityGroup}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
              >
                {template.fields.discloseToEntityGroup.entries.map(option => (
                  <span
                    style={template.styles.list}
                    key={option.key}
                    className="form-check"
                  >
                    <Field
                      key={option.key}
                      component={RadioButton}
                      name="discloseToEntityGroup"
                      id={option.key}
                      checked={option.checked}
                      value={option.key}
                      label={option.value}
                    />
                  </span>
                ))}
              </RadioButtonGroup>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {formik.touched.discloseToEntityGroup &&
                formik.errors.discloseToEntityGroup ? (
                <span className="input-error-text">
                  {formik.errors.discloseToEntityGroup}
                </span>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div style={template.styles.disclaimer}>
                <Markdown>{template.disclaimer}</Markdown>
              </div>
            </div>
          </div>

          {(areFieldsDisabled) && (
            <div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="discloseToName">
                      {" "}
                      <span style={template.styles.label}>
                        {template.fields.discloseToName.label}
                      </span>{" "}
                      <Required
                        value={
                          template.fields.discloseToName.validations.required.value
                        }
                      />
                    </label>
                    <input
                      {...formik.getFieldProps("discloseToName")}
                      type="text"
                      name="discloseToName"
                      className={inputClasses(
                        formik.touched.discloseToName &&
                        formik.errors.discloseToName
                      )}
                      placeholder={template.fields.discloseToName.hint}
                    />
                    {formik.touched.discloseToName &&
                      formik.errors.discloseToName && (
                        <span className="input-error-text">
                          {formik.errors.discloseToName}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="discloseToPhone">
                      {" "}
                      <span style={template.styles.label}>
                        {template.fields.discloseToPhone.label}{" "}
                      </span>
                      <Required
                        value={template.fields.discloseToPhone.validations.required}
                      />
                    </label>
                    <input
                      {...formik.getFieldProps("discloseToPhone")}
                      type="text"
                      name="discloseToPhone"
                      className={inputClasses(
                        formik.touched.discloseToPhone &&
                        formik.errors.discloseToPhone
                      )}
                      placeholder={template.fields.discloseToPhone.hint}
                    />
                    {formik.touched.discloseToPhone &&
                      formik.errors.discloseToPhone && (
                        <span className="input-error-text">
                          {formik.errors.discloseToPhone}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="discloseToFax">
                      <span style={template.styles.label}>
                        {template.fields.discloseToFax.label}
                      </span>{" "}
                    </label>
                    <input
                      {...formik.getFieldProps("discloseToFax")}
                      type="text"
                      name="discloseToFax"
                      className={inputClasses(
                        formik.touched.discloseToFax && formik.errors.discloseToFax
                      )}
                      placeholder={template.fields.discloseToFax.hint}
                    />
                    {formik.touched.discloseToFax &&
                      formik.errors.discloseToFax && (
                        <span className="input-error-text">
                          {formik.errors.discloseToFax}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="discloseToAddressLine1">
                      <span style={template.styles.label}>
                        {template.fields.discloseToAddressLine1.label}
                      </span>{" "}
                      <Required
                        value={
                          template.fields.discloseToAddressLine1.validations
                            .required
                        }
                      />
                    </label>
                    <input
                      {...formik.getFieldProps("discloseToAddressLine1")}
                      type="text"
                      maxLength={
                        template.fields.discloseToAddressLine1.validations.maxLength
                          .value
                      }
                      name="discloseToAddressLine1"
                      id="discloseToAddressLine1"
                      className={inputClasses(
                        formik.touched.discloseToAddressLine1 &&
                        formik.errors.discloseToAddressLine1
                      )}
                      placeholder={template.fields.discloseToAddressLine1.hint}
                    />
                    {formik.touched.discloseToAddressLine1 &&
                      formik.errors.discloseToAddressLine1 && (
                        <span className="input-error-text">
                          {formik.errors.discloseToAddressLine1}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    {template.fields.discloseToAddressLine2.label && (
                      <label htmlFor="discloseToAddressLine2">
                        <span style={template.styles.label}>
                          {template.fields.discloseToAddressLine2.label}
                        </span>{" "}
                        <Required
                          value={
                            template.fields.discloseToAddressLine2.validations
                              .required.value
                          }
                        />
                      </label>
                    )}
                    <input
                      {...formik.getFieldProps("discloseToAddressLine2")}
                      type="text"
                      maxLength={
                        template.fields.discloseToAddressLine2.validations.maxLength
                          .value
                      }
                      name="discloseToAddressLine2"
                      id="discloseToAddressLine2"
                      className={inputClasses(
                        formik.touched.discloseToAddressLine2 &&
                        formik.errors.discloseToAddressLine2
                      )}
                      placeholder={template.fields.discloseToAddressLine2.hint}
                    />
                    {formik.touched.discloseToAddressLine2 &&
                      formik.errors.discloseToAddressLine2 && (
                        <span className="input-error-text">
                          {formik.errors.discloseToAddressLine2}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    {template.fields.discloseToCity.label && (
                      <label htmlFor="discloseToCity">
                        <span style={template.styles.label}>
                          {template.fields.discloseToCity.label}
                        </span>{" "}
                        <Required
                          value={
                            template.fields.discloseToCity.validations.required
                              .value
                          }
                        />
                      </label>
                    )}
                    <input
                      {...formik.getFieldProps("discloseToCity")}
                      type="text"
                      name="discloseToCity"
                      className={inputClasses(
                        formik.touched.discloseToPhone && formik.errors.discloseCity
                      )}
                      maxLength={
                        template.fields.discloseToCity.validations.maxLength.value
                      }
                      placeholder={template.fields.discloseToCity.hint}
                    />
                    {formik.touched.discloseToCity &&
                      formik.errors.discloseToCity && (
                        <span className="input-error-text">
                          {formik.errors.discloseToCity}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    {template.fields.discloseToState.label && (
                      <label htmlFor="discloseToState">
                        <span style={template.styles.label}>
                          {template.fields.discloseToState.label}
                        </span>{" "}
                        <Required
                          value={
                            template.fields.discloseToState.validations.required
                              .value
                          }
                        />
                      </label>
                    )}
                    <input
                      {...formik.getFieldProps("discloseToState")}
                      type="text"
                      name="discloseToState"
                      className={inputClasses(
                        formik.touched.discloseToPhone &&
                        formik.errors.discloseState
                      )}
                      maxLength={
                        template.fields.discloseToState.validations.maxLength.value
                      }
                      placeholder={template.fields.discloseToState.hint}
                    />
                    {formik.touched.discloseToState &&
                      formik.errors.discloseToState && (
                        <span className="input-error-text">
                          {formik.errors.discloseToState}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                    {template.fields.discloseToZip.label && (
                      <label htmlFor="discloseToZip">
                        <span style={template.styles.label}>
                          {template.fields.discloseToZip.label}
                        </span>{" "}
                        <Required
                          value={
                            template.fields.discloseToZip.validations.required.value
                          }
                        />
                      </label>
                    )}
                    <input
                      {...formik.getFieldProps("discloseToZip")}
                      type="text"
                      name="discloseToZip"
                      className={inputClasses(
                        formik.touched.discloseToZip && formik.errors.discloseToZip
                      )}
                      placeholder={template.fields.discloseToZip.hint}
                    />
                    {formik.touched.discloseToZip &&
                      formik.errors.discloseToZip && (
                        <span className="input-error-text">
                          {formik.errors.discloseToZip}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      :
      <></>
  );
};

export default DiscloseTo;
