import React from "react";
import './ValidationErrorPopupBody.css';

const ValidationErrorPopupBody = ({messages, styles, errors}) => (
  
  <div id='error-popup' style={{ padding: "10px 20px 0px 20px" }}> 
    <div style={styles.topMessage}>{messages.topMessageText}</div>
    {errors &&
      errors.map(s => (
        <div key={s.name}>
          {/* TODO; control */}
          <div style={styles.sectionName}>{s.name}</div>
          {/* TODO; control */}
          {s.messages.map((m, idx) => (
            <div key={idx} style={styles.message}>{messages.messageTextPrefix} {m}</div>
          ))}
          
        </div>
      ))}
      <div style={styles.bottomMessage}>{messages.bottomMessageText}</div>
  </div>
);

export default ValidationErrorPopupBody;
