import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from './context';
import Popper from './Popper';

const Content = ({ children }) => (
  <Consumer>
    {({ placement, targetNode, showPopover }) => showPopover ? (
      <Popper placement={placement} target={targetNode}>
        {children}
      </Popper>
    ) : null}
  </Consumer>
);

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;