import React from "react";
import Required from "../../../components/Required";
import Checkbox from "../../../components/Checkbox";
import { Field } from "formik";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";
import Facilities_7224 from '../orgs/7224/facilities'
import NYPOutpatientFacilities from '../orgs/7042/facilities';
import '../styles/css/authToRelease.css';
import { DisabledCheckbox } from '../orgs/7042/components'
import Facilities_7227 from '../orgs/7227/facilities'
import BothwellFacilities from '../orgs/7302/facilities'
import BaptistFacilitySection from '../orgs/7291/facilitySection'
import AlleghenyFacilitySection from '../orgs/7150/FacilitiesSection'
import NorthwellFacilitySection from '../orgs/7229/FacilitiesSection'
import SBCMFacilitySection from '../orgs/7342/FacilitiesSection'
import SBCPMPFacilitySection from '../orgs/7343/FacilitiesSection'
import THRFacilitySection from '../orgs/7451/FacilitiesSection'
import CarbonFacilitySection from '../orgs/7307/FacilitiesSection'
import Popover from "../../../../formik/refactorOut/components/Popper/Popover";
import infoCircle from '../styles/assets/info-circle.png'

const AuthorizationToRelease = ({ formik, inputClasses, props, template, config }) => {

  const orgId = config.context.organization;

  const tripartiteOrg = orgId === '1007042' || orgId === '1007239' || orgId === '1007101' || orgId === '1006987'
  // console.log(formik.getFieldProps("facilitiesGroup"))
  // console.log(formik.values)
  console.log(orgId)

  if (orgId === '1007291') { return <BaptistFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config}/> }
  if (orgId === '1007150') { return <AlleghenyFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config}/> }
  // if (orgId === '1007342') { return <SBCMFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config}/> }
  if (orgId === '1007229') { return <NorthwellFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config} preCheckedFacility={true}/> }
  if (orgId === '1007342') { return <NorthwellFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config} preCheckedFacility={true}/> }
  if (orgId === '1007343') { return <SBCPMPFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config} preCheckedFacility={false}/> }
  if (orgId === '1007451') { return <THRFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config} preCheckedFacility={false}/> }
  if (orgId === '1007307') { return <CarbonFacilitySection formik={formik} inputClasses={inputClasses} props={props} template={template} config={config}/>
}
  
  if (orgId === '1007224') {
    return (
      <Facilities_7224 orgId={orgId} formik={formik} template={template} config={config} />
    )
  }

  if (orgId === '1007227') {
    return (
      <Facilities_7227 orgId={orgId} formik={formik} template={template} config={config} />
    )
  }
  
  if (orgId === '1007302') {
    return <BothwellFacilities template={template} formik={formik} config={config}/> 
  }
  
  if (tripartiteOrg) {
    return <NYPOutpatientFacilities orgId={orgId} formik={formik} template={template} config={config} />
  }
  return (
    config.page.form.sections.authorizationToReleaseSection.active ?
      <div id='medicalFacilitiesRequested' className='form-section'>
        <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
        <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>

        <div id='ordinary-facilities' className='form-fields' style={{flexFlow: 'column wrap', maxHeight:250, marginBottom: 10}}>
          {template.fields.facilitiesGroup.entries.map((item, index) => {
            return (
              (!item.type) && (item.key !== 'otherFac') &&
              // Check to see if the above line is needed for Temple to work
              <FacilityItem item={item} template={template} formik={formik} config={config} disabled={item.disabled} key={index}/>
            )
          })
          }
          
        </div>
        {orgId !== '1007278' && <FacilityItem item={{key: 'otherFac', value: 'Specific facility or provider at CBC Trial Works'}} template={template} formik={formik} config={config} disabled={false} key={54321}/> }

        {(tripartiteOrg) && <NYPOutpatientFacilities orgId={orgId} formik={formik} template={template} config={config} />}

      </div>
      :
      <></>
  );
};

export const FacilityItem = ({ item, template, formik, config, disabled }) => {

  return (
    <div key={item.key} className={`check-option tp-facility-item ${item.key === 'otherFac' && 'other-tp-facility-item'}`}>
      {!disabled && <Checkbox style={template.styles.list} className="custom-form-check checkbox" key={item.value} name="facilitiesGroup" label={(item.key === 'otherFac') ? "" : item.value} value={item.key} />}
      {disabled && <DisabledCheckbox template={template} item={item} />}
      {" "}
      {item.key === "otherFac" &&
        <div className="form-group" style={{ marginBottom: 0, alignSelf: 'flex-start', width: '100vw', position: 'relative', left: -27 }}>
          <input
            {...formik.getFieldProps("otherFac")}
            style={{ width: '40%' }}
            type="text"
            maxLength={template.fields.otherFac.validations.maxLength.value}
            name="otherFac"
            id="otherFac"
            className="form-control"
            style={config.page.form.styles.hint}
            placeholder={template.fields.otherFac.hint}
          />
          {formik.touched.otherFac &&
            formik.errors.otherFac ? (
            <span className="input-error-text">
              {formik.errors.otherFac}
            </span>
          ) : null}
        </div>
      }
      
      {item.value === 'NYP Westchester' && 
      <Popover placement={'right'}>
        <Popover.Target>
          <img className='info-circle' src={infoCircle}></img>
        </Popover.Target>
        <Popover.Content>{'Formerly known as Lawrence'}</Popover.Content>
      </Popover>}
    </div>
  )
}



export default AuthorizationToRelease;
