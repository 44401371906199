import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopperJS from 'popper.js';

class Popper extends Component {
  static propTypes = {
    placement: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    placement: 'bottom',
  };

  content = null;
  arrow = null;
  popper = null;

  componentDidMount() {
    this.initPopper();
  }

  componentDidUpdate() {
    this.updatePopper();
  }

  componentWillUnmount() {
    this.destroyPopper();
  }

  initPopper = () => {
    this.popper = new PopperJS(
      this.props.target,
      this.content,
      {
        placement: this.props.placement,
        modifiers: {
          arrow: {
            element: this.arrow,
          },
        },
      }
    );
  };

  updatePopper = () => {
    this.popper && this.popper.update();
  };

  destroyPopper = () => {
    this.popper && this.popper.destroy();
  };

  setContentNode = (node) => { this.content = node; };

  setArrowNode = (node) => { this.arrow = node; };

  render() {
    const { placement, children } = this.props;

    return (
      <div
        ref={this.setContentNode}
        className={`popover bs-popover-${placement}`}
        role="tooltip"
      >
        <div ref={this.setArrowNode} className="arrow" />
        <div className="popover-body">{children}</div>
      </div>
    );
  }
}

export default Popper;
