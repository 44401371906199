import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DynamicFormContainer from "./UI/DynamicFormContainer";
import {ConfirmationPageContainer} from './UI/screens/confirmationPage/ConfirmationPageContainer';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {instkey} from './config/Constants'
import './App.scss'

const appInsights = new ApplicationInsights({ config: { instrumentationKey: instkey } });
appInsights.loadAppInsights();
appInsights.trackPageView();

const App = () => {
  
   let parseableQueryString = new URLSearchParams(window.location.search);
   const session = {
     id: parseableQueryString.get('session'),
     form: 3,
   };

  return (
      <Router basename={'/build'}>
        <div className="app">    
          <Switch>
            <Route path="/" exact render={() => <DynamicFormContainer session={session} />} />
            <Route path="/confirmationPage" render={() =>  <ConfirmationPageContainer session={session}/>} />
            {/* <Route path="/tempconfirmationPage" render={() =>  <TempConfirmationPage accessCode = {parseableQueryString.get("accessCode")}/>} /> */}
            {/* TODO; <Route path="/review" exact component={ReviewContainer} />  */}
            {/* TODO; add 404 route - generic page or redirect to client page*/}
          </Switch>
        </div>
      </Router>
  );
};

export default App;
