export const convertFormData = (formData, org, formSpec, formik) => {
    
    // console.log(formik)
    const pdf_releaseItemsList = []
    formData.infoToReleaseGroup.forEach((releaseCode, index) => {
        formSpec.page.form.sections.informationToReleaseSection.fields.infoToReleaseGroup.entries.forEach((releaseItemEntry, index) => {
            if (releaseCode === releaseItemEntry.key) {
                pdf_releaseItemsList.push(releaseItemEntry.value)
            }
        })
    })
    if (formData.infoToReleaseGroupOther) {
        pdf_releaseItemsList.push(formData.infoToReleaseGroupOther)
    }
    // formik.setFieldValue("pdf_releaseItemsList", pdf_releaseItemsList)
    formData.pdf_releaseItemsList = pdf_releaseItemsList.join('  |  ');
    formData.pdf_facilityList = formData.facilitiesGroup.join(', ').concat((formData.otherFac) ? ', ' + formData.otherFac : '')
    
    if (org === '1007307') {
        let carbonFacilitiesGroupFormat = []
        formData.facilitiesGroup.forEach((facility, index) => {
            let carbonFacility = ""
            for (let i = 1; i < 5; i++) {
                if (facility[`customFacName${i}`] || facility[`customFacDoctor${i}`]) {
                    // carbonFacility += ` ${i > 1 ? '|' : ''} ${facility[`customFacName${i}`] ? 'Facililty: ' + facility[`customFacName${i}`] + ',' : ''} ${facility[`customFacAddress${i}`] ? 'Address: ' + facility[`customFacAddress${i}`] + ',' : ''} ${facility[`customFacDoctor${i}`] ? 'Physician: ' + facility[`customFacDoctor${i}`] : ''}`
                    carbonFacility += `${
                        i > 1 && (facility[`customFacName${i}`] || facility[`customFacAddress${i}`] || facility[`customFacDoctor${i}`])
                          ? '    |    '
                          : ''
                      }${facility[`customFacName${i}`] ? 'Facility: ' + facility[`customFacName${i}`] : ''}${
                        facility[`customFacAddress${i}`] ? ', Address: ' + facility[`customFacAddress${i}`] : ''
                      }${facility[`customFacDoctor${i}`] ? ', Physician: ' + facility[`customFacDoctor${i}`] : ''}`;
                      
                }
            }
            carbonFacilitiesGroupFormat.push(carbonFacility)
        })
        
        console.log(carbonFacilitiesGroupFormat)
        formData.facilitiesGroup = carbonFacilitiesGroupFormat;
        formData.facilitiesList = carbonFacilitiesGroupFormat.join();
    }
    else if (org === '1007342' || org === '1007229' || org === '1007042' || org === '1007239' || org === '1007101') {
        
        let convertedFacilitiesGroup = [`${formData.facilitiesGroup}`]
        

        formData.facilitiesObj = formData.facilitiesGroup;
        formData.facilitiesGroup = convertedFacilitiesGroup;
        console.log(formData.facilitiesGroup)
    }
    else {
    }
    return formData
    
    

}