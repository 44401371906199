import React from 'react';
import { ErrorMessage } from "formik";
import Required from "../../../components/Required";


export const CaseNumberSection = ({formik, section, styles, inputClasses}) => {

    
    return(
        <div id='caseNumberSection' className="form-section">    
                <div style={{display: "flex"}} id='caseNumber-form-field' className="form-field">
                    <label style={{"whiteSpace": "nowrap", marginRight:"10px"}} htmlFor="caseNumber" id='caseNumber-label'>
                        <span style={styles.label}>
                            {section.fields.caseNumber.label}
                            {" "}
                            <Required
                                value={
                                    section.fields.caseNumber.validations
                                    .required.value
                                }
                            />
                        </span>{" "}
                    
                    </label>
                        {/* <div className="col-8" id='caseNumber-textbox'> */}
                    <input
                        {...formik.getFieldProps("caseNumber")}
                        maxLength={
                            section.fields.caseNumber.validations.maxLength.value
                        }
                        type="text"
                        name="caseNumber"
                        id='caseNumber-textbox'
                        style={styles.hint}
                        className={inputClasses(
                            formik.touched.caseNumber &&
                            formik.errors.caseNumber
                        )}
                        placeholder={section.fields.caseNumber.hint}
                    />
                            
                        {/* </div> */}
                </div>
                    
                    <div style={{"marginLeft": "40%"}} className="row">
                        <ErrorMessage name="caseNumber">
                            {msg => <div className="input-error-text text-center">{msg}</div>}
                        </ErrorMessage>
                    </div>
          
    </div>)
}