import * as Yup from "yup";

export const purposeOfDisclosureValidations = section => {
  if (section.active) {
    return Yup.object().shape({
      disclosurePurposeGroup: Yup.string().required(
        () =>
          section.fields.disclosurePurposeGroup.validations.required.value &&
          section.fields.disclosurePurposeGroup.validations.required.message
      ),
      disclosurePurposeGroupOther: Yup.string()
        .when("disclosurePurposeGroup", {
          is: val => val === "other",
          then: Yup.string().required(
            section.fields.disclosurePurposeGroupOther.validations.required
              .message
          )
        })
        .max(
          section.fields.disclosurePurposeGroupOther.validations.maxLength
            .value,
          section.fields.disclosurePurposeGroupOther.validations.maxLength
            .message
        )
    });
  }
};
