import * as Yup from "yup";

import {
    phoneRegex,
    zipRegex,
    faxRegex,
  } from "./validationFunctions";
  


export const repContactInfoValidations = section => {
    if (section.active) {
      return Yup.object().shape({
        repRole: Yup.string()
        .required(
          () =>
            section.fields.repRole.validations.required.value &&
            section.fields.repRole.validations.required.message
        ),
        discloseToOrgName:  Yup.string()
        .max(
          section.fields.discloseToOrgName.validations.maxLength.value,
          section.fields.discloseToOrgName.validations.maxLength.message
        )
        .required(
          () =>
            section.fields.discloseToOrgName.validations.required.value &&
            section.fields.discloseToOrgName.validations.required.message
        ),
        discloseToFName:  Yup.string().required(
            () =>
              section.fields.discloseToFName.validations.required.value &&
              section.fields.discloseToFName.validations.required.message
          ),
          discloseToLName:  Yup.string().required(
            () =>
              section.fields.discloseToLName.validations.required.value &&
              section.fields.discloseToLName.validations.required.message
          ),
        discloseToPhone: Yup.string()
        .required(
          () =>
            section.fields.discloseToPhone.validations.required.value &&
            section.fields.discloseToPhone.validations.required.message
        )
        .matches(
          phoneRegex,
          section.fields.discloseToPhone.validations.validPhone.message
        ),
        discloseToEmail: Yup.string()
        .required(
          () =>
          section.fields.discloseToEmail.validations.required.value &&
            section.fields.discloseToEmail.validations.required.message
        )
        .email(
          section.fields.discloseToEmail.validations.validEmail.message
        )
        .max(
          section.fields.discloseToEmail.validations.maxLength.value,
          section.fields.discloseToEmail.validations.maxLength.message
        ),
        discloseToFax: Yup.string()
        .matches(
          faxRegex,
          'Fax number is not valid'
        ),
        discloseToAddressLine1:  Yup.string()
        .required(
          () =>
            section.fields.discloseToAddressLine1.validations.required
              .value &&
            section.fields.discloseToAddressLine1.validations.required
              .message
        )
        .max(
          section.fields.discloseToAddressLine1.validations.maxLength
            .value,
          section.fields.discloseToAddressLine1.validations.maxLength
            .message
        ),
        discloseToAddressLine2: Yup.string()
        
        .max(
          section.fields.discloseToAddressLine2.validations.maxLength
            .value,
          section.fields.discloseToAddressLine2.validations.maxLength
            .message
        ),
        discloseToCity:  Yup.string()
        .required(
          () =>
            section.fields.discloseToCity.validations.required.value &&
            section.fields.discloseToCity.validations.required.message
        )
        .matches(/^[A-Za-z-]+$/, 'City must contain only letters')
        .max(
          section.fields.discloseToCity.validations.maxLength.value,
          section.fields.discloseToCity.validations.maxLength.message
        ),
        discloseToState:  Yup.string()
        .required(
          () => {
           return section.fields.discloseToState.validations.required.value &&
            section.fields.discloseToState.validations.required.message
          }
        )
        .matches(/^[A-Za-z]{2}$/, 'State must contain only letters')
        .max(
          section.fields.discloseToState.validations.maxLength.value,
          section.fields.discloseToState.validations.maxLength.message
        ),
        discloseToMName: Yup.string()
        .max(
          section.fields.discloseToMName.validations.maxLength.value,
          section.fields.discloseToMName.validations.maxLength.message
        ),
        discloseToZip: Yup.string()
        .required(
          () =>
            section.fields.discloseToZip.validations.required.value &&
            section.fields.discloseToZip.validations.required.message
        )
        .matches(
          zipRegex,
          section.fields.discloseToZip.validations.validZipcode.message
        )
      });
    }  
  };
  
  
  
  