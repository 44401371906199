import {
  INITIALIZE,
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL
} from "./actions/actionTypes";

export const formReducer = (state = {}, action) => {
  switch (action.type) {
    case INITIALIZE: {
      return { ...state, ...action.payload };
    }
    case SHOW_ERROR_MODAL: {
      return { ...state, ...action.payload };
    }
    case HIDE_ERROR_MODAL: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};
