import React, {useEffect, useState, useCallback} from "react";
import Required from "../../../../components/Required";
import Checkbox from "../../../../components/Checkbox";
import { DisabledCheckbox } from '../../orgs/7042/components'
import {FacilityItem} from '../../sections/AuthorizationToReleaseSection'
import './FacilitiesSection.scss'

const SBCPMPFacilitySection = ({ formik, inputClasses, props, template, config }) => {

    const [internistFacilitiesDisplay, setInternistFacilitiesDisplay] = useState('none')
    
    useEffect(()=>{
      const facilitiesGroup = formik.values.facilitiesGroup
      facilitiesGroup.includes('Facility15') ? setInternistFacilitiesDisplay('flex') : setInternistFacilitiesDisplay('none')
    }, [formik.values.facilitiesGroup])
    
    return (
        config.page.form.sections.authorizationToReleaseSection.active ?
          <div id='medicalFacilitiesRequested' className='form-section'>
            <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
            <label className='non-textbox-label'> <span style={template.styles.label}> {'I authorize'} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>
    
            <ul style={{paddingInlineStart: 0, display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between'}}>
              <RadioOption_CPMP facilityNum={'1'} facilityValue={'Stony Brook Anaesthesiology (Pain Management), UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'2'} facilityValue={'Stony Brook Dermatology, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'3'} facilityValue={'Stony Brook Family and Preventive Medicine, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'4'} facilityValue={'Neurology Associates of Stony Brook, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'5'} facilityValue={'New York Spine & Brain Surgery, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'6'} facilityValue={'University Associates in Obstetrics & Gynecology, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'7'} facilityValue={'Stony Brook Opthalmology, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'8'} facilityValue={'Stony Brook Orthopedics Associates, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'9'} facilityValue={'Stony Brook Children\'s Service, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'10'} facilityValue={'Stony Brook Psychiatric Associates, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'12'} facilityValue={'Stony Brook Radiation Oncology, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'13'} facilityValue={'Stony Brook Surgical Associates, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'14'} facilityValue={'Stony Brook Urology, UFPC'} formik={formik}/>
              <RadioOption_CPMP facilityNum={'11'} facilityValue={'Stony Brook Radiology, UFPC *For Radiology Images please call (631) 638-0618'} formik={formik}/>
              <div className='' style={{width: '48%', marginRight: 19}}>
                <input type="radio" className='radio-button' id='facility15' name='facility-select' onChange={() => { formik.setFieldValue('facilitiesGroup', ['Facility15']) }}/>
                <label htmlFor="facility15" style={{fontWeight: 400}}>Stony Brook Internists, UFPC</label>
                <div className='internist-facilities' style={{display: internistFacilitiesDisplay, marginTop: 10, flexWrap: 'wrap', }}>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Cardiology'} name="facilitiesGroup" label={'Cardiology'} value={'Facility16'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Endocrinology'} name="facilitiesGroup" label={'Endocrinology'} value={'Facility17'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Gastroenterology'} name="facilitiesGroup" label={'Gastroenterology'} value={'Facility18'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Infectious Disease'} name="facilitiesGroup" label={'Infectious Disease'} value={'Facility19'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Pulmonology'} name="facilitiesGroup" label={'Pulmonology'} value={'Facility20'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Rheumatology'} name="facilitiesGroup" label={'Rheumatology'} value={'Facility21'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Sleep Center'} name="facilitiesGroup" label={'Sleep Center'} value={'Facility22'} /></div>
                  <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Medical Oncology'} name="facilitiesGroup" label={'Medical Oncology'} value={'Facility23'} /></div>
                </div>
              </div>
            </ul>
          </div>
          :
          <></>
      );
};

export default SBCPMPFacilitySection;

const RadioOption_CPMP = ({facilityNum, facilityValue, formik}) => {
    return (
      <div className='radio-option' style={{marginBottom: 10, width: '48%', alignItems: 'flex-start'}} onChange={() => { formik.setFieldValue('facilitiesGroup', [`Facility${facilityNum}`])}}>
        <input type="radio" className='radio-button' id={`facility${facilityNum}`} name='facility-select' />
        <label htmlFor={`facility${facilityNum}`}>{facilityValue}</label>
      </div>
    )
}

