import React, { useEffect, useState } from "react";
import {
  registerLocales as registerLocalesForDatePickers,
  modalCustomStyles
} from "../../../config/Configuration";
import { Formik } from "formik";
import FormikProps from "../../../formik/refactorOut/FormikProps";
import Modal from "react-modal";
import PatientInfomation from "./sections/PatientInformationSection";
import AuthorizationToRelease from "./sections/AuthorizationToReleaseSection";
import InformationToRelease from "./sections/InformationToReleaseSection";
import DisclosureOfSensitiveInformation from "./sections/DisclosureOfSensitiveInformationSection";
import PurposeOfDisclosure from "./sections/PurposeOfDisclosureSection";
import DeliveryMethod from "./sections/DeliveryMethodSection";
import DiscloseToSection from "./sections/DiscloseToSection";
import ReleaseDatesSection from "./sections/ReleaseDatesSection";
import {CaseNumberSection} from './sections/CaseNumberSection';
import {SupportingDocsSection}  from './sections/SupportingDocsSection';
import ValidationErrorPopupBody from "./ValidationSummaryPopupBody/ValidationErrorPopupBody";
import { mapErrorsToSection } from "../../../functions/mapErrorsToSection";
import { inputClasses } from "../../../formik/refactorOut/styles";
import { hideErrorModalAction } from "../../../context/actions/hideErrorModalAction";
import { showErrorModalAction } from "../../../context/actions/showErrorModalAction";
import {RepContactInfoSection} from './sections/RepContactInfoSection';
import {AdditionalInfoSection} from './sections/AdditionalInfoSection';
import {TempPage} from '../../../formik/refactorOut/TempPage';
import axios from "axios";
import {SummaryModal} from '../SummaryModal/SummaryModal';
// import {LoadingScreen} from '../../components/LoadingScreen';
import { spaEndpointHost, vraUX, apiHost, getwayApi } from "../../../config/Constants";
import './styles/sass/requestForm.scss'
import './sections/styles/formSections.scss'
import vzaLogoHoriz from './styles/assets/verisma_logo.png';
import ErrorFocus from '../../../formik/refactorOut/ErrorFocus'
import {TriLogoAndBanner} from './orgs/7042/components'
import {LogoAndTitle} from './sections/LogoAndTitle';
import {LoadingScreen} from '../../components/LoadingScreen'
import SubmissionErrorScreen from '../SubmissionError/view';
import {convertFormData} from './Logic'

registerLocalesForDatePickers();

const DynamicForm = (props) => {
  
  const imgPlacement = {
    center: "center-block",
    left: "float-left",
    right: "float-right"
  }
  
  const { config, fields, validations, showErrorModal, errors } = props.formInfo;
  const { dispatch } = props;
  const [nextPage, setNextPage] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)
  const [showSummary, setShowSummary] = useState(false);
  const [errorTofocus, setErrorTofocus] = useState(false);
  const [submissionError, setSubmissionError] = useState(false)
  const [failedSubmissionData, setFailedSubmissionData] = useState(null)
  const [localFormik, setLocalFormik] = useState(null)
  const session = props.session;
  const dbFormSpec = config;

  const tripatiteOrg = config.context.organization === '1007042' || config.context.organization === '1007239' || config.context.organization === '1007101' || config.context.organization === '1006987';
  const THRForm = config.context.organization === '1007451'
//api/client/logo/
//console.log("Dynamic from:");
//console.log(props.form);
//console.log(formSpecJSON);


// const config = formSpecJSON;

const resetSubmissionData = (formik) => {
  const FSD = failedSubmissionData
  setFailedSubmissionData(false)
  formik.setValues(failedSubmissionData); 
}

useEffect(() => {
    document.title = config.page.title;
  }, [config]);

  const submitFileData = (url, session) => {
    return async (file) => {
      const fileReader = new FileReader();

      function getByteArray(file) {
        return new Promise(function(resolve, reject) {
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = function(ev) {
                const array = new Uint8Array(ev.target.result);
                const fileByteArray = [];
                for (let i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
                resolve(fileByteArray);  // successful
            }
            fileReader.onerror = reject; // call reject if error
        })
      }
    
      let submissionPayload = new FormData();
      submissionPayload.append("fileName", file.name);
      submissionPayload.append("contentType", file.type);
      submissionPayload.append("sessionId", session);
      submissionPayload.append("fileByte",  file);
      
        await axios
          .post(`${url}/api/session/supportingdoc/upload`, submissionPayload, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(res => {
            // console.log(res);
            
          })
          .catch(err => {
            if(err.response !== undefined)
              console.log(err.response)            
          });
    }
  }

  const deleteFileData = (url, session) => {
    return async (filename) => {
      //api/session/supportingdoc/remove/{sessionId}/{filename}

      await axios
      .delete(`${url}/api/session/supportingdoc/remove/${session}/${filename}`)
      .then(res => {
        // console.log(res);
        
      })
      .catch(err => {
        if(err.response !== undefined)
          console.log(err.response)            
      });

    }
  }


  const submitUserData = (url, session, esign, organization, formid) => {
    return async (formData, actions) => {
        setNextPage(true);
        formData = convertFormData(formData, organization, dbFormSpec, localFormik)
        let submissionPayload;
        let fileData;
        let subData = formData;
        
        const config = {headers: {'content-type': 'multipart/form-data'}}
        subData.esign = esign;
        subData.releaseFromDate = subData.releaseFromDate.toDateString();
        subData.releaseToDate = subData.releaseToDate.toDateString();
        subData.patientInfoDob = subData.patientInfoDob.toDateString();
        subData.session = session;
        subData.organizationId = organization;
        subData.formId = formid;

        if(subData.supportingDoc){
          submissionPayload = new FormData();
          let counter = 1;
          fileData = subData.supportingDoc.map(x => {
            submissionPayload.append(`file${counter}`, x)
            counter++;
            return {
              name: x.name,
              type: x.type,
              size: x.size
            }
          });
          
         
          subData.fileData = fileData;

          delete subData.supportingDoc;
          
          submissionPayload.append('userData', JSON.stringify(subData));
          
          
        }else{
          submissionPayload = {data: JSON.stringify(subData)}
          config = {headers: {'content-type': 'application/json'}}
        }

        // create file data object
        // list of files
        const apiUrl = `${url}/api/submission`;
        
        await axios
          .post(apiUrl, submissionPayload, config)
          .then(res => {
            window.location = res.data.url;
            
          })
          .catch(err => {
            if(err.response !== undefined)
              console.log(err.response)
              setFailedSubmissionData(formData)
              setSubmissionError({error: err})
            
            // TODO; map infrastructure error to domain error
            
          });
    }
}

console.log(props)
  

if(!nextPage){
  return (
    <div id='request-form'>
        {/* // ================================================== Error Validation Modal ================================================== */}
        <Modal
          ariaHideApp={false}
          isOpen={showErrorModal}
          onRequestClose={() => dispatch(hideErrorModalAction())}
          style={modalCustomStyles}
        >
          <div id='validation-modal-container'>
            <div id='validation-modal-heading' style={config.page.form.styles.validationModal.heading}>
              {config.page.form.components.validationModal.headingText}
            </div>
            
            <ValidationErrorPopupBody
              messages={config.page.form.components.validationModal}
              styles={config.page.form.styles.validationModal}
              errors={errors}
            />
            
            <div style={{ padding: 20 }} className="text-center">
              <button
                className="btn btn-light "
                onClick={() => {
                  
                  dispatch(hideErrorModalAction())}}
              >
                {config.page.form.components.validationModal.okButtonText}
              </button>
            </div>
          </div>
        </Modal>
        
        {/* {config.page.form.styles.externalStyles &&
          <style>
            {config.page.form.styles.externalStyles.map(style => {
              return `@import url('${style.importUrl}');`;
            })}
          </style>
        } */}
        
        {/*================================================== Logo & Title Section ================================================== */}
        

      {tripatiteOrg ?
        <TriLogoAndBanner config={config} getwayApi={getwayApi} formId={props.formId} imgPlacement={imgPlacement}/>
        :
        <LogoAndTitle imgPlacement={imgPlacement} config={config} getwayApi={getwayApi} formId={props.formId}/>
      }
        
        {/*================================================== Form ================================================== */}
        <Formik
          initialValues={fields}
          validationSchema={validations}
          onSubmit={(values, {setStatus, setErrors}) => {
            dispatch(hideErrorModalAction())
            window.history.pushState({summary: true}, window.location.href) 
            return setShowSummary(true)
            // TODO; initialize some form of processing indicator
            // TODO; persist user state (key, value, type) and route to the request summary page
            // e.g. { "key" : "patientInfoFirstName", "value" : "John", "type" : "Textbox"}
          }}
        >
          {formik => {
            const { isValid, errors, dirty, handleSubmit } = formik;
            !localFormik && setLocalFormik(formik)
            // failedSubmissionData && resetSubmissionData(formik)
            return (
              <form id='form-sections'
                onSubmit={e => {
                  e.preventDefault();
                  isValid ?
                    handleSubmit(e)
                    :
                    setErrorTofocus(true);
                    dispatch( showErrorModalAction(mapErrorsToSection(config, errors)) );
                  // TODO; trigger UI validation messages on failed validation (touched/errors) without adding handleSubmit(e)
                }}
              >
                  <RepContactInfoSection formik={formik} inputClasses={inputClasses} template={{...config.page.form.sections.repContactInfoSection, styles: { ...config.page.form.styles }}} styles={config.page.form.styles} orgId={config.context.organization} selectorMatch={config.page.form.components.selectorMatch} config={config} />
                  <PatientInfomation formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections.patientInformationSection, styles: { ...config.page.form.styles }, locale: config.page.locale }} styles={config.page.form.styles} config={config} />
                  <InformationToRelease formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections.informationToReleaseSection, styles: { ...config.page.form.styles }, locale: config.page.locale }} config={config}/>
                  <SupportingDocsSection formik={formik} template={{ section: config.page.form.sections.supportingDocsSection, styles:{ ...config.page.form.styles } }} fileSubmissionAction={submitFileData(getwayApi, session)} fileDeletionAction={deleteFileData(getwayApi, session)} config={config}/>
                  <AuthorizationToRelease formik={formik} inputClasses={inputClasses} config={config} template={{ ...config.page.form.sections.authorizationToReleaseSection, styles: { ...config.page.form.styles } }} />
                  {THRForm && <DeliveryMethod formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections.deliveryMethodSection, styles: { ...config.page.form.styles } }} config={config}/>}
                  <DiscloseToSection formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections.discloseToSection, styles: { ...config.page.form.styles } }} config={config}/>
                  <DisclosureOfSensitiveInformation formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections .disclosureOfSensitiveInformation, styles: { ...config.page.form.styles } }} config={config}/>
                  <PurposeOfDisclosure formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections.purposeOfDisclosureSection, styles: { ...config.page.form.styles } }} config={config}/>
                  <AdditionalInfoSection formik={formik} inputClasses={inputClasses} config={config} template={{ ...config.page.form.sections.additionalInfoSection, styles: { ...config.page.form.styles }}} />
                  <ReleaseDatesSection formik={formik} inputClasses={inputClasses} template={{ ...config.page.form.sections.releaseDatesSection, styles: { ...config.page.form.styles } }} config={config} supportingDocsSection={config.page.form.sections.supportingDocsSection} dirty={dirty}/>
                
                {/*================================================== Submit & Cancel Buttons ==================================================*/}
                
                  {/* <div id='submit-button-section' className='form-section'>
                    <button disabled={!dirty} style={config.page.form.styles.buttons.act} id='submit-button' type="submit" > {config.page.form.components.submitButton.text} </button>
                  </div>   */}
                  {/* TODO; remove when date picker/formik issue is resolved */}
               
                {/*================================================== Summary Modal ==================================================*/}
                
                {showSummary && <SummaryModal formik={formik} formId={props.formId} config={config} userInput={formik.values} submitData={submitUserData(apiHost, session, props.esign, config.context.organization, props.formId)} isModelOpen={true} setSummary={setShowSummary}/>}
                <ErrorFocus {...formik} errorToFocus={errorTofocus} setErrorToFocus={setErrorTofocus}/>
              </form>
            );
          }}
        </Formik>
      </div>
    );
    } else if (nextPage && !submissionError) {
      return (
      <LoadingScreen loadingMessage={(props.formInfo.fields.discloseToPhone === "") ? "Loading the form. We'll be just a moment." : "Finalizing your request. We'll be just a moment."}/>
      )
    }
    else{
      return (
        <SubmissionErrorScreen errorInfo={submissionError} orgLogo={`${getwayApi}/api/client/logo/${props.formId}`} formSpec={config} failedSubmissionData={failedSubmissionData} setFailedSubmissionData={setFailedSubmissionData} setNextPage={setNextPage} setSubmissionError={setSubmissionError} formik={localFormik} setShowSummary={setShowSummary}/>
      )
    }
};

export default DynamicForm;
