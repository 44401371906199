import React from 'react';
import Checkbox from '../../../../components/Checkbox'
import { Field } from "formik";
import {FacilityItem} from '../../sections/AuthorizationToReleaseSection'

const BothwellFacilities = ({template, formik, config}) => {
    return(
        <div className="bothwell-facilities">
            <div id='medicalFacilitiesRequested' className='form-section'>
                <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
                <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} </label>
                <div className="facility_list" style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'flex-start'}}>
                    {template.fields.facilitiesGroup.entries.map((facility, index)=>{
                        return (
                            facility.key !== 'otherFac' && 
                            <div className='check-option'><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={facility.value} name="facilitiesGroup" label={(facility.key === 'otherFac') ? "" : facility.value} value={facility.key} bothwell={true}/></div>
                        )
                    })}
                </div>
                
                <Field name={'facilitiesGroup'}>
                    {({ field, form }) => (
                        <div className='other-field' style={{display: 'flex', marginTop: 25, marginBottom: 25}}>
                            <input type="checkbox" className='checkbox' checked={field.value.includes('allLocations')} onChange={()=>{
                                field.value.includes('allLocations') ?
                                form.setFieldValue('facilitiesGroup', [])
                                :
                                form.setFieldValue('facilitiesGroup', ['allLocations'])
                            }}/>
                            <label className='paragraph' htmlFor="" style={{fontFamily: 'Arial', color: 'black', fontSize: '1em', fontWeight: 600}}>Request records from all locations</label>
                        </div>
                    )}
                </Field>
                <FacilityItem item={{key: 'otherFac', value: 'Specific facility or provider at CBC Trial Works'}} template={template} formik={formik} config={config} disabled={false} key={54321}/>
            </div>
        </div>
    )
}

export default BothwellFacilities;