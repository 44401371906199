import * as Yup from "yup";

import {
  phoneRegex,
  zipRegex,
  dateCantBeInFuture,
  minimumYearsOld,
  maxYearAgo,
} from "./validationFunctions";

const whichFieldsAreActive = (fields) => {
  const fieldsToValidate = {};

  if(fields.patientInfoFirstName.active){
    fieldsToValidate.patientInfoFirstName = Yup.string()
      .max(
        fields.patientInfoFirstName.validations.maxLength.value,
        fields.patientInfoFirstName.validations.maxLength.message
      )
      .required(
        () =>
          fields.patientInfoFirstName.validations.required
            .value &&
          fields.patientInfoFirstName.validations.required.message
      )
  }
  if(fields.patientInfoLastName.active){
    fieldsToValidate.patientInfoLastName = Yup.string()
      .max(
        fields.patientInfoLastName.validations.maxLength.value,
        fields.patientInfoLastName.validations.maxLength.message
      )
      .required(
        () =>
          fields.patientInfoLastName.validations.required.value &&
          fields.patientInfoLastName.validations.required.message
      )
  }

  if(fields.patientInfoDob.active){
    fieldsToValidate.patientInfoDob =Yup.date()
      .required(
        () =>
          fields.patientInfoDob.validations.required.value &&
          fields.patientInfoDob.validations.required.message
      )
      // .max(
      //   dateCantBeInFuture,
      //   section.fields.patientInfoDob.validations.notFutureDate.message
      // )
    
      .test(
        "patientInfoDob",
        fields.patientInfoDob.validations.maxYearsAgo.message,
        function(date){
          var todaysDate = new Date();
          var enteredDate = new Date(date);
          return enteredDate.getFullYear() >= 1900;
        }
      ).nullable()
      .test(
        "patientInfoDob",
        fields.patientInfoDob.validations.notFutureDate.message,
        function(date){
          var todaysDate = new Date();
          var enteredDate = new Date(date);
          return todaysDate > enteredDate;
        }
      )
  }

  if(fields.patientInfoOtherName.active){
    fieldsToValidate.patientInfoOtherName = Yup.string()
      .required(
        () =>
          fields.patientInfoOtherName.validations.required
            .value &&
          fields.patientInfoOtherName.validations.required.message
      )
      .max(
        40,
        fields.patientInfoOtherName.validations.maxLength.message
      )
  }
  if(fields.patientInfoMrn.active){
    fieldsToValidate.patientInfoMrn = Yup.string()
      .required(
        () =>
          fields.patientInfoMrn.validations.required.value &&
          fields.patientInfoMrn.validations.required.message
      )
      .max(
        fields.patientInfoMrn.validations.maxLength.value,
        fields.patientInfoMrn.validations.maxLength.message
      )
  
  }


  if(fields.patientInfoEmail.active){
    fieldsToValidate.patientInfoEmail = Yup.string()
      .required(
        () =>
          fields.patientInfoEmail.validations.required.value &&
          fields.patientInfoEmail.validations.required.message
      )
      .email(
        fields.patientInfoEmail.validations.validEmail.message
      )
      .test(
        "emailValidatation",
        fields.patientInfoEmail.validations.validEmail.message,
        function(email) {
          return /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(email)
        }
      )
  }
  if(fields.patientInfoEmailConfirmation.active){
    fieldsToValidate.patientInfoEmailConfirmation = Yup.string()
      .required(
        () =>
          fields.patientInfoEmailConfirmation.validations.required
            .value &&
          fields.patientInfoEmailConfirmation.validations.required
            .message
      )
      .email(
        fields.patientInfoEmailConfirmation.validations.validEmail
          .message
      )
      .test(
        "emailValidatation",
        fields.patientInfoEmail.validations.validEmail.message,
        function(email) {
          return /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(email)
        }
      )
      .oneOf(
        [
          Yup.ref(
            fields.patientInfoEmailConfirmation.validations
              .mustMatch.value
          ),
          null
        ],
        fields.patientInfoEmailConfirmation.validations.mustMatch
          .message
      )
  }

  if(fields.patientInfoPhone.active){
    fieldsToValidate.patientInfoPhone =  Yup.string()
      .required(
        () =>
          fields.patientInfoPhone.validations.required.value &&
          fields.patientInfoPhone.validations.required.message
      )
      .max(
        fields.patientInfoPhone.validations.maxLength
          .value,
        fields.patientInfoPhone.validations.maxLength
          .message
      )
      .matches(
        phoneRegex,
        fields.patientInfoPhone.validations.validPhone.message
      )
  
  }

  if(fields.patientInfoPhoneConfirmation.active){
    fieldsToValidate.patientInfoPhoneConfirmation = Yup.string()
      .required(
        () =>
          fields.patientInfoPhoneConfirmation.validations.required
            .value &&
          fields.patientInfoPhoneConfirmation.validations.required
            .message
      )
      .max(
        fields.patientInfoPhoneConfirmation.validations.maxLength
          .value,
        fields.patientInfoPhoneConfirmation.validations.maxLength
          .message
      )
      .matches(
        phoneRegex,
        fields.patientInfoPhoneConfirmation.validations.validPhone
          .message
      )
      .oneOf(
        [
          Yup.ref(
            fields.patientInfoPhoneConfirmation.validations
              .mustMatch.value
          ),
          null
        ],
        fields.patientInfoPhoneConfirmation.validations.mustMatch
          .message
      )
  
  }

  if(fields.patientInfoAddressLine1.active){
    fieldsToValidate.patientInfoAddressLine1 = Yup.string()
      .required(
        () =>
          fields.patientInfoAddressLine1.validations.required
            .value &&
          fields.patientInfoAddressLine1.validations.required
            .message
      )
      .max(
        fields.patientInfoAddressLine1.validations.maxLength
          .value,
        fields.patientInfoAddressLine1.validations.maxLength
          .message
      )
  
  }

  if(fields.patientInfoAddressLine2.active){
    fieldsToValidate.patientInfoAddressLine2 = Yup.string()
      .required(
        () =>
          fields.patientInfoAddressLine2.validations.required
            .value &&
          fields.patientInfoAddressLine2.validations.required
            .message
      )
      .max(
        fields.patientInfoAddressLine2.validations.maxLength
          .value,
        fields.patientInfoAddressLine2.validations.maxLength
          .message
      )
  
  }

  
  if(fields.patientInfoCity.active){
    fieldsToValidate.patientInfoCity = Yup.string()
      .required(
        () =>
          fields.patientInfoCity.validations.required.value &&
          fields.patientInfoCity.validations.required.message
      )
      .max(
        fields.patientInfoCity.validations.maxLength.value,
        fields.patientInfoCity.validations.maxLength.message
      )
  
  }

  if(fields.patientInfoState.active){
    fieldsToValidate.patientInfoState =  Yup.string()
      .required(
        () =>
          fields.patientInfoState.validations.required.value &&
          fields.patientInfoState.validations.required.message
      )
      .max(
        fields.patientInfoState.validations.maxLength.value,
        fields.patientInfoState.validations.maxLength.message
      )
  
  }

  if(fields.patientInfoZip.active){
    fieldsToValidate.patientInfoZip = Yup.string()
      .required(
        () =>
          fields.patientInfoZip.validations.required.value &&
          fields.patientInfoZip.validations.required.message
      )
      .max(
        fields.patientInfoZip.validations.maxLength.value,
        fields.patientInfoZip.validations.maxLength.message
      )
      .matches(
        zipRegex,
        fields.patientInfoZip.validations.validZipcode.message
      )
  
  }

  return fieldsToValidate;
}

export const patientInformationValidations = section => {
    if (section.active) {
    return Yup.object().shape(       
      whichFieldsAreActive(section.fields)
      
    );
  }  
};
