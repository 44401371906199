import React, {useState, useEffect} from 'react';
import axios from "axios";
import './styles/sass/captcha.scss'
import { spaEndpointHost, vraUX, apiHost, getwayApi, captchaUrl } from "../../../config/Constants";
import {inputClasses} from '../../../formik/refactorOut/styles'
import PBV_icon from '../../assets/pbv.png'

export const CaptchaContainer = ({setCaptcha, form, formId}) => {
    const [captchaRespObj, setCaptchaRespObj] = useState(null);
    const [userCatchaInput, setUserCatchaInput] = useState('');
    const [errorState, setErrorState] = useState(false);

    

    useEffect(() => {
        const doFetch = async () => {
    
          try {
            // NOTE; axios is currently pointing to file system json file.  Use the router match prop to construct the appropriate url
            // to retrieve the configuration (and pesisted form state specific to the session if it is a back request)
            // simulating userState example below
            
            
    
            await axios
            .get(`${captchaUrl}/api/captcha`)
              .then(res => {
                setCaptchaRespObj(res.data)
                
              })
              .catch(err => {
               
              });
            } catch (error) {
              console.log(error)
          }
        };
    
        doFetch();
      }, [false]);

      const submitCaptchaData = async () => {
         
    
        try {
          // NOTE; axios is currently pointing to file system json file.  Use the router match prop to construct the appropriate url
          // to retrieve the configuration (and pesisted form state specific to the session if it is a back request)
          // simulating userState example below
  
          await axios
          .post(`${captchaUrl}/api/captcha/${captchaRespObj.id}/match/${userCatchaInput}`)
            .then(res => {
                // console.log(res.data)
              setCaptcha(res.data)
              if(!res.data){
                console.log("bad captcha")
                setErrorState(true)
              }
             
            })
            .catch(err => {
               console.log("bad captcha")
                setErrorState(true)
               
            });
          } catch (error) {
            console.log("bad captcha")
            setErrorState(true)
        }
      };

      // console.log(form, 'formCaptcha')
      
      if(form === null){
        return <p></p>
      }
      const formStyles = form.config.page.form.styles
      const formSpec = form.config.page.form
      
      
  return (
    <div id='captcha-page'>
      <div id='captcha-container' className="">
        <img id='captcha-logo' src={`${getwayApi}/api/client/logo/${formId}`} style={form.config.page.form.styles.logos.captcha} />
        <div id='security-check-sentence'><p style={{ ...formSpec.components.captcha.header.styles }}>{formSpec.components.captcha.header.text}</p> </div>
        {
          captchaRespObj != null &&
          <div id='captcha-content'>            
            <p id='security-check-subtext' style={{ ...formStyles.label, marginBottom: 30 }}>{formSpec.components.captcha.instruction}</p>
            <img id='captcha-image' src={`data:image/png;base64, ${captchaRespObj.image}`}></img>

            <input id='captcha-input'
              onKeyDown={(e) => { e.keyCode === 13 && submitCaptchaData() }}
              placeholder={formSpec.components.captcha.textBoxHint}
              onChange={
                e => { setUserCatchaInput(e.target.value); }
              }
            >
            </input>
            {errorState && <small>Invalid Capatcha</small>}
            <button
              id='captcha-button'
              style={formStyles.buttons.act}
              onClick={e => submitCaptchaData()}
            >
              {formSpec.components.captcha.buttonText}
            </button>
          </div>
        }

      </div>
      <img src={PBV_icon} alt="Powered by Verisma" className='pbv-icon' />
    </div>

  );
}


