import { initializeFormAction } from "../context/actions/initializeFormAction";
import {getwayApi} from "../config/Constants";
import axios from "axios";

export const fetchFormData = async (setError, setLoading, session, dispatch, setEsign, setFormId) => {
    setError(false);
    setLoading(true);

    try {
      // NOTE; axios is currently pointing to file system json file.  Use the router match prop to construct the appropriate url
      // to retrieve the configuration (and pesisted form state specific to the session if it is a back request)
      // simulating userState example below
      
      const userState = { patientInfoFields: { patientInfoFirstName: "" } };

      await axios.get(`${getwayApi}/api/session/client/${session.id}`)
        .then(res => {
          setEsign(res.data.esign)
          setFormId(res.data.formId)
          // console.log(res.data.formId)
          dispatch(initializeFormAction(session, JSON.parse(res.data.formSpecs), userState));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log('error:', err)
          // TODO; map infrastructure error to domain error
          setError(err);
        });
      } catch (error) {
        setLoading(false);
        console.log(error)
        // TODO; map infrastructure error to domain error
      setError(error);
    }
  };