import * as Yup from "yup";

export const supportingDocsValidations = section => {
  // console.log(section.fields.supportingDoc.validations)
    if (section.active) {
        return Yup.object().shape({
            supportingDoc: Yup.array().required(
              section.fields.supportingDoc.validations.required.message
            )
            .test(
                'is-file-type',
                section.fields.supportingDoc.validations.fileType.message,
                function checkIfFilesAreCorrectType(files){
                    let valid = true
                    if (files) {
                      files.map(file => {
                        if (!['application/pdf'].includes(file.type)) {
                          valid = false
                        }
                      })
                    }
                    return valid
                  }
              )
              .test(
                'file-limit',
                section.fields.supportingDoc.validations.fileLimit.message,
                function checkIfFilesAreCorrectType(files){
                    
                    if (files) {
                      return files.length <= 3;
                    }
                    return true;
                  }
              )
              .test(
                'is-file-big',
                section.fields.supportingDoc.validations.fileSize.message,
                function checkIfFilesAreCorrectType(files){
                    let valid = true
                    if (files) {
                      files.map(file => {
                        if (['application/pdf'].includes(file.size < 10000000)) {
                          valid = false
                        }
                      })
                    }
                    return valid
                  }
              )
        });
    }  
};