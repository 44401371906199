import { Formik } from 'formik';
import React from 'react';
import BaptistFormHeader from '../orgs/7291/formHeader'

export const LogoAndTitle = ({imgPlacement, config, getwayApi, formId}) => {

    if (config.context.organization === '1007291') { return <BaptistFormHeader imgPlacement={imgPlacement} config={config} getwayApi={getwayApi} formId={formId}/> }
    
    return (
        <div id='logo-and-title-container'>
          <img className={imgPlacement[config.page.form.logo.position]} id='form-logo' src={`${getwayApi}/api/client/logo/${formId}`} alt={config.page.form.logo.alt} style={{ ...config.page.form.styles.logos.form }} />
          <h1 style={config.page.form.styles.heading} id={"form-title"} > {config.page.form.heading.text} </h1>
        </div>
    )
}