import * as moment from 'moment';
import {INITIALIZE} from './actionTypes';
import { allValidations } from '../../formik/validations';

export const initializeFormAction = (client, config, data) => {
  console.log("intialize test")
  /* 
  NOTE; 
  the fields defined here must match the the fields in each section 
  in the configuration file and persisted user state.  the initialization
  process configures the initial values for the Formik library 

  if a section is active the fields are spread to the fields object
  if there is persisted user state it is spread to the fields object
  */
  let fields = {};

  const additionalInfoSection = config.page.form.sections.additionalInfoSection;
  const additionalInfoFields = {
   
    repDesc: ""
  }
  
  if(additionalInfoSection.active){
    fields = {...fields, ...additionalInfoFields}
  }

  const repContactInfoSection = config.page.form.sections.repContactInfoSection;
  const repContactInfoFields = {
    discloseToOrgName: "",
    discloseToFName : "",
    discloseToMName : "",
    discloseToLName: "",
    discloseToPhone: "",
    discloseToEmail: "",
    discloseToAddressLine1: "",
    discloseToAddressLine2: "",
    discloseToCity: "",
    discloseToState: "",
    discloseToZip: "",
    repRole: "",
    requestorNameId: 0,
    requestorContactId: 0


  }

  if(repContactInfoSection.active){
    fields = { ...fields, ...repContactInfoFields };
  }

  const supportingDocSec = config.page.form.sections.supportingDocsSection;
  if(supportingDocSec.active){
     // required doc section
    const supportingdocFields = {
      supportingDoc: []
    }

    //add if section is active
    fields = {...fields, ...supportingdocFields}
    //fields = {...fields, data.suppportDoc}
  }
 
// case number section
  const caseNumberSection = config.page.form.sections.repContactInfoSection.fields.caseNumber;
  const caseNumberField = {caseNumber: ""}

  if(caseNumberSection.active){
    fields = { ...fields, ...caseNumberField };
  }

  // PATIENT INFORMATION SECTION
  // section template
  const patientInfoFields = {
    
  };
  const patientInfo = config.page.form.sections.patientInformationSection;
  for (let [key, value] of Object.entries(patientInfo.fields)) {
    if(value.active){
      patientInfoFields[key] = "";
    }
}

  if (patientInfo.active) {
    // configuration
    fields = { ...fields, ...patientInfoFields };

    // user state
    if (data.patientInfoFields)
      fields = { ...fields, ...data.patientInfoFields };
  }

  // AUTHORIZATION TO RELEASE SECTION
  // section template
  const authorizationToReleaseFields = {
    facilitiesGroup: [],
    physiciansGroup: [],
    allOrNameGroup: "",
    namedPhysicians: "",
    otherFac: "",
    // ReleaseMode: '',
    releasemodePDF: ''
  };
  const authorizationToRelease =
    config.page.form.sections.authorizationToReleaseSection;
  if (authorizationToRelease.active) {
    // configuration
    fields = { ...fields, ...authorizationToReleaseFields };

    // user state
    if (data.authorizationToReleaseFields)
      fields = {
        ...fields,
        ...data.authorizationToReleaseFields
      };
  }

  // DISCLOSE TO SECTION
  // section template
  const discloseToFields = {
    discloseToEntityGroup: "",
    discloseToName: "",
    discloseToPhone: "",
    discloseToFax: "",
    discloseToAddressLine1: "",
    discloseToAddressLine2: "",
    discloseToCity: "",
    discloseToState: "",
    discloseToZip: ""
  };
  const discloseTo = config.page.form.sections.discloseToSection;
  if (discloseTo.active) {
    // configuration
    fields = { ...fields, ...discloseToFields };

    // user state
    if (data.discloseToFields)
      fields = { ...fields, ...data.discloseToFields };
  }

  // INFORMATION TO RELEASE SECTION
  // section template
  const informationToReleaseFields = {
    infoToReleaseGroup: [],
    infoToReleaseGroupOther: "",
    releaseToDate: "",
    releaseFromDate: ""
  };
  const infoToRelease = config.page.form.sections.informationToReleaseSection;

  if(infoToRelease.fields.certBox.active){
    informationToReleaseFields.certBox = false;
  }
  if (infoToRelease.active) {
    // configuration
    fields = { ...fields, ...informationToReleaseFields };

    // user state
    if (data.infoToReleaseFields)
      fields = { ...fields, ...data.infoToReleaseFields };
  }

  // SENSITIVE INFORMATION SECTION
  // section template
  const sensitiveInfoFields = {
    sensitiveInfo: []
  };
  const sensitiveInfo =
    config.page.form.sections.disclosureOfSensitiveInformation;
  if (sensitiveInfo.active) {
    // configuration
    fields = { ...fields, ...sensitiveInfoFields };

    // user state
    if (data.sensitiveInfoFields)
      fields = { ...fields, ...data.sensitiveInfoFields };
  }

  // DELIVERY METHODS SECTION
  // section template
  const deliveryMethodFields = {
    ReleaseMode: ''
  };
  const deliveryMethod = config.page.form.sections.deliveryMethodSection;
  if (deliveryMethod.active && deliveryMethod.fields.deliveryMethodGroup.active) {
    // configuration
    fields = { ...fields, ...deliveryMethodFields };

    // user state
    if (data.deliveryMethodFields)
      fields = { ...fields, ...data.deliveryMethodFields };
  }

  // PURPOSE OF DISCLOSURE SECTION
  // section template
  const purposeOfDisclosureFields = {
    disclosurePurposeGroup: "",
    disclosurePurposeGroupOther: ""
  };
  const purposeOfDisclosure =
    config.page.form.sections.purposeOfDisclosureSection;
  if (purposeOfDisclosure.active) {
    // configuration
    fields = { ...fields, ...purposeOfDisclosureFields };

    // user state
    if (data.purposeOfDisclosureFields)
      fields = {
        ...fields,
        ...data.purposeOfDisclosureFields
      };
  }
  
  // TODO; these dates should come from the API
  // RELEASE DATES SECTION
  // section template
  const releaseDatesFields = {
    todaysDate: moment().format("MM/DD/YYYY"),
   
     
  };
  const releaseDates = config.page.form.sections.releaseDatesSection;
 
    if(config.page.form.sections.releaseDatesSection.fields.consentExpirationDate.active){
      releaseDatesFields.consentExpirationDate =  moment()
      .add(config.page.form.sections.releaseDatesSection.fields.consentExpirationDate.age, "d")
      .format("MM/DD/YYYY")
    }
  if (releaseDates.active) {
    // configuration
    fields = { ...fields, ...releaseDatesFields };

    // user state
    if (data.releaseDatesFields)
      fields = { ...fields, ...data.releaseDatesFields };
  }

  const validations = allValidations(config);

  return {
    type: INITIALIZE,
    payload: {
      client,
      fields,
      config,
      validations
    }
  }
}