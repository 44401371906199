import React, { useState } from "react";
import { ErrorMessage } from "formik";
import Markdown from "markdown-to-jsx";
import { Field } from "formik";
import Required from "../../../components/Required";

export const SupportingDocsSection = ({ formik, template, fileSubmissionAction, fileDeletionAction, config }) => {
  const [error, giveError] = useState(false);

  return (
    config.page.form.sections.supportingDocsSection.active ?
      <div id="supportingDocSection" className="form-section">
        <h2 style={template.styles.section} className="section-heading">
          {"Supporting Documents"}
        </h2>

        <div className="form-fields">
          {/* <label style={template.styles.label}>
            {template.section.fields.disclaimers.header} <Required value={true} />
          </label> */}

          <div style={template.styles.disclaimer}>
            {/* <Markdown>{template.section.disclaimer.body}</Markdown> */}
            {/* <p className="disclaimer-text">
              {template.section.fields.disclaimers.requestNotice}
            </p>
            <p className="disclaimer-text">
              {template.section.fields.disclaimers.emailNotice}
            </p> */}
            <p
              className="disclaimer-text"
              style={{ fontWeight: 700, marginBottom: 3.2 }}
            >
              {template.section.fields.disclaimers.authHeader}
            </p>
            <Markdown>{template.section.fields.disclaimers.authNotice}</Markdown>
          </div>

          <label>
            <span style={template.styles.label}>
              {template.section.fields.supportingDoc.label}
            </span>{" "}
            <Required value={true} />
          </label>
          <br />

          <div style={{ cursor: "pointer", zIndex: 0 }} className="input-group mb-3">
            <div className="custom-file">
              <input
                id="supportingDoc"
                name="supportingDoc"
                type="file"
                onChange={(event) => {
                  if (
                    event.currentTarget.files[0] &&
                    event.currentTarget.files[0].size < 15000000
                  ) {
                    giveError(false);
                    if (!formik.getFieldProps("supportingDoc").value) {
                      formik.setFieldTouched("supportingDoc", true, true);
                      formik.setFieldValue("supportingDoc", [
                        event.currentTarget.files[0],
                      ]);
                      fileSubmissionAction(event.currentTarget.files[0]);
                    } else {
                      formik.setFieldTouched("supportingDoc", true, true);
                      formik.setFieldValue(
                        "supportingDoc",
                        formik
                          .getFieldProps("supportingDoc")
                          .value.concat(event.currentTarget.files[0])
                      );
                      fileSubmissionAction(event.currentTarget.files[0]);
                    }
                  } else {
                    // console.log(formik);
                    giveError(template.section.fields.supportingDoc.validations.fileSize.message);
                  }
                }}
              />
              <label
                className="custom-file-label"
                style={template.styles.hint}
                htmlFor="supportingDoc"
              >
                {template.section.fields.supportingDoc.hint}
              </label>
            </div>
          </div>

          <div id="supportingDoc-error-message" style={{ fontSize: 14, color: "red" }} >
            {error}
          </div>

          <ErrorMessage name="supportingDoc">
            {(msg) => (
              <div style={template.styles.error} className="input-error-text">
                {msg}
              </div>
            )}
          </ErrorMessage>

          <div
            style={{ marginLeft: "2%", marginTop: "1%", msrginRight: "2%" }}
            className="container"
          >
            <ul className="list-group">
              {formik.getFieldProps("supportingDoc").value &&
                formik.getFieldProps("supportingDoc").value.map((item) => {
                  return (
                    <li class="list-group-item">
                      {item.name}
                      <button
                        type="button"
                        className="float-right close"
                        aria-label="Close"
                        onClick={() => {
                          formik.validateForm();
                          formik.setFieldValue(
                            "supportingDoc",
                            formik
                              .getFieldProps("supportingDoc")
                              .value.filter((x) => x != item)
                          );
                          fileDeletionAction(item.name);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      :
      <></>
  );
};
