import React from 'react';
import Checkbox from '../../../../components/Checkbox'

const HSSDocField = ({template, formik}) => {
    return (
        <div id='physician-field' style={{ display: 'flex', width: '48.42%' }}>
            <Checkbox style={{ ...template.styles.list, display: 'flex' }} className="custom-form-check checkbox" key={'HSS Physician Office Record(s)'} name="infoToReleaseGroup" label={'HSS Physician Office Record(s)'} value={'22314'} />
            <input
                type="text"
                className='form-control'
                placeholder={"Physician's Name"}
                onChange={(e) => {
                    formik.setFieldValue('otherFac', e.target.value)
                    console.log(formik.values)
                }}
            />
        </div>
    )
}

export default HSSDocField;