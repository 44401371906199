import React, { useEffect } from "react";
import { Field } from "formik";
import Required from "../../../components/Required";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";
import Popover from "../../../../formik/refactorOut/components/Popper/Popover";
import { popoverLocation } from '../../../../config/Configuration'

const DeliveryMethod = ({ formik, inputClasses, template, config }) => {
  useEffect(() => {
    console.log(formik.values)
  }, [formik.values])
  
  return (
    config.page.form.sections.deliveryMethodSection.active ?
      <div id='deliveryMethodSection' className='form-section'>

        <h2
          style={template.styles.section}
          className="section-heading">
          {template.title}
        </h2>



        <div className='form-fields' id='delivery-method-form-field'>

          <label style={{marginBottom: 22}}>
            <span style={template.styles.label}>{template.fields.deliveryMethodGroup.label}</span>{" "}
            <Required value={true} />
          </label>



          {template.fields.deliveryMethodGroup.active && <div className="form-group">
            <RadioButtonGroup
              id="deliveryMethodGroup"
              value={formik.values.deliveryMethodGroup}
              error={formik.errors.deliveryMethodGroup}
              touched={formik.touched.deliveryMethodGroup}
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
            >
              {template.fields.deliveryMethodGroup.entries.map(option => (
                <span style={template.styles.list} key={option.key} className="form-check">
                  <Field
                    key={option.key}
                    component={RadioButton}
                    // name="deliveryMethodGroup"
                    name="deliveryMethodGroup"
                    id={option.key}
                    checked={option.checked}
                    value={option.key}
                    label={option.value}
                    config={config}
                  />
                  {/* <Popover placement={popoverLocation}>
                    <Popover.Target>
                      <i className="fa fa-info-circle fa_custom"></i>
                    </Popover.Target>
                    <Popover.Content>{option.tooltip}</Popover.Content>
                  </Popover> */}
                </span>
              ))}
            </RadioButtonGroup>
            {/* not sure how to render errors dependent on other fields - declaritively here fixes logic - can it be done imperatively with yup? */}
            {formik.touched.deliveryMethodGroup &&
              formik.errors.deliveryMethodGroup ? (
              <span className="input-error-text">
                {formik.errors.deliveryMethodGroup}
              </span>
            ) : null}
          </div>}

        </div>
      </div>
      :
      <></>
  );
};

export default DeliveryMethod;


{/* <div style={{display:'inline'}}>Delivery Method</div>
                  <div>
                    <input type="radio" name='test' id='Email' value='Email' onChange={()=>{formik.setFieldValue('deliveryMethodGroup', '2'); console.log(formik.values)}}/>
                    <label htmlFor="Email">Email</label>
                  </div>
                  <div>
                    <input type="radio" name='test' id='CD/DVD' value='CD/DVD' onChange={()=>{formik.setFieldValue('deliveryMethodGroup', '7'); console.log(formik.values)}}/>
                    <label htmlFor="CD/DVD">CD/DVD</label>
                  </div>
                  <div>
                    <input type="radio" name='test' id='Mail' value='Mail' onChange={()=>{formik.setFieldValue('deliveryMethodGroup', '5'); console.log(formik.values)}}/>
                    <label htmlFor="Mail">Mail</label>
                  </div> */}