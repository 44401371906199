
// REFACTOR; separate configs into separate files and import/export using index.js

// TODO; consider trying to load only the required locale per client configuration
import {registerLocale} from 'react-datepicker';
import enUS from "date-fns/locale/en-us";
import es from "date-fns/locale/es";
import zhCN from "date-fns/locale/zh-cn";
import fr from "date-fns/locale/fr";

export const registerLocales = _ => {
  registerLocale("en-us", enUS);
  registerLocale("es", es);
  registerLocale("zh-cn", zhCN);
  registerLocale("fr", fr);
}



// DESIGN; these attributes control the structure - not really client specific - where do we put this? 
export const modalCustomStyles = {
  content: {
    width: "50rem",
    maxWidth: "calc(100vw - 2rem)",
    maxHeight: "calc(100vh - 2rem)",
    boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.25)",
    overflowY: "auto",
    position: "relative",
    padding: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    overflow: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"

  }
};

// NOTE; options are left, right, top, bottom
export const popoverLocation = "right"; 