import React, {useEffect, useState} from 'react';
import PBV_icon from '../assets/pbv.png'
import {getwayApi} from '../../config/Constants'


export const LoadingScreen = ({ config, formId, loadingMessage }) => {

    const [delay, setDelay] = useState(false)
    
    useEffect(()=>{
        setTimeout(()=>{setDelay(true)}, 10000)
    })
    
    return (
        <div className='loader-page' style={{ borderColor: 'red', borderWidth: 1 }}>
            <div className='spinner-container'>
                <div className='spinner' ></div>
                <p className='loading-message'>{loadingMessage}</p>
                {delay && <p className='loading-message delay-message'>We seem to have hit a snag. <strong>Try refreshing the page</strong></p>}
            </div>
            <img src={PBV_icon} alt="powered by verisma icon" className='pbv-icon' />
        </div>
    );
}


// export const LoadingScreen = () => {
//    return(
//     <div style={{marginTop: "50%"}}>
//         <h4 className="text-center">Loading</h4>
//         <div style={{margin: "0 auto"}} class="sk-chase">
//             <div class="sk-chase-dot"></div>
//             <div class="sk-chase-dot"></div>
//             <div class="sk-chase-dot"></div>
//             <div class="sk-chase-dot"></div>
//             <div class="sk-chase-dot"></div>
//             <div class="sk-chase-dot"></div>
//         </div>
//   </div>
//    );
// }