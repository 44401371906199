import React, {useEffect, useState, useCallback} from "react";
import Required from "../../../../components/Required";
import Checkbox from "../../../../components/Checkbox";
import { DisabledCheckbox } from '../../orgs/7042/components'
import {FacilityItem} from '../../sections/AuthorizationToReleaseSection'
import './FacilitiesSection.scss'

const NorthwellFacilitySection = ({ formik, inputClasses, props, template, config, preCheckedFacility }) => {

    
    // const [facilitiesGroup, setFacilitiesGroup] = useState({customFacName1:"", customFacAddress1: "", customFacName2:"", customFacAddress2: "", customFacName3:"", customFacAddress3: "", customFacName4:"", customFacAddress4: "", })
    const [facilitiesGroup, setFacilitiesGroup] = useState()

    
    
    
    useEffect(()=>{
      // When local facilitiesGroup changes, update formik facilitiesGroup

      console.log(formik.values.facilitiesGroup)
      const emptyFacilitiesGroup = facilitiesGroup && Object.values(facilitiesGroup).every((value)=>{ return !value })
      
      if (emptyFacilitiesGroup) {
        formik.setFieldValue('facilitiesGroup', [])
      }
      else {
        formik.setFieldValue('facilitiesGroup', [facilitiesGroup])
        console.log(facilitiesGroup)
      }
      console.log(formik.values.facilitiesGroup)
      
    }, [facilitiesGroup])

    useEffect(()=>{
      console.log(formik.values.facilitySelected)
      if (formik.values.facilitiesGroup[0]) {
          (!formik.values.facilitiesGroup[0].customFacName1 || !formik.values.facilitiesGroup[0].customFacAddress1) ? formik.setFieldValue('facilitySelected', "") : formik.setFieldValue('facilitySelected', true)
      }
    }, [formik.values.facilitiesGroup])

    
    const updateFacilities = (e, firstIndex, secondIndex) => {
        setFacilitiesGroup(facilitiesGroup.map((innerArray, index)=>{
            console.log(index)
            
            if (index === firstIndex) {
                innerArray.map((info, index)=>{
                    if (index === secondIndex) {
                        return innerArray
                    }
                })
                
                return [e.target.value, innerArray[1]]
            }
            
            else {
                return innerArray
            }
      })); 
        console.log(facilitiesGroup)}
    
    return (
        config.page.form.sections.authorizationToReleaseSection.active ?
          <div id='medicalFacilitiesRequested' className='form-section'>
            <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
            {preCheckedFacility &&
              <label className='non-textbox-label'> 
                <span style={template.styles.label}> {'Specify the facilities you authorize to release medical information'} </span> {" "} 
                {/* <Required value={template.fields.facilitiesGroup.validations.required.value} />  */}
              </label>
            }
    
              {preCheckedFacility &&
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <input type="checkbox" checked={true} className='checkbox' id='defaultCheck'/> <label htmlFor="defaultCheck" style={{fontWeight: 400}}>{template.fields.facilitiesGroup.entries[0].value}</label>
                </div>
              }
            <div id='ordinary-facilities' className='form-fields' style={{flexFlow: 'column wrap', maxHeight:250, marginBottom: 10, marginTop: 20}}>

              <label htmlFor="" style={{marginBottom: 10}}>List clinics and providers below (You must enter information into the first name and address boxes, respectively) <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>
              <div className='name-address-facility-input-container-holder'>
                  <FacilitySet setIndex={1} facilitiesGroup={facilitiesGroup} setFacilitiesGroup={setFacilitiesGroup} formik={formik}/>
                  <FacilitySet setIndex={2} facilitiesGroup={facilitiesGroup} setFacilitiesGroup={setFacilitiesGroup} formik={formik}/>
                  <FacilitySet setIndex={3} facilitiesGroup={facilitiesGroup} setFacilitiesGroup={setFacilitiesGroup} formik={formik}/>
                  <FacilitySet setIndex={4} facilitiesGroup={facilitiesGroup} setFacilitiesGroup={setFacilitiesGroup} formik={formik}/>
              </div>
            </div>
          </div>
          :
          <></>
      );
};

export default NorthwellFacilitySection;

const FacilitySet = ({setIndex, facilitiesGroup, setFacilitiesGroup, formik}) => {
  
  // const updateFacilitiesGroup = (event) => {
  //   setFacilitiesGroup(facilitiesGroup.map((facilityArray, mapIndex)=>{
  //     if (mapIndex === setIndex) {
  //       return [event.target.value, innerArray[1]]
  //     }
  //     else {
  //       return innerArray
  //     }
  //   }))
  // }
  
  return (
        <div style={{display:'flex'}} className='name-address-facility-input-container'>
            <input type="text" className='form-control name-address-facility-input' placeholder={"Provider's first and last name"} onChange={(e)=>{
                setFacilitiesGroup(
                  // {...facilitiesGroup, [setIndex]: [e.target.value, facilitiesGroup[setIndex][1]]}
                    {...facilitiesGroup, [`customFacName${setIndex}`]: e.target.value}
                );
                
              }}
            />
            <input type="text" className='form-control name-address-facility-input' placeholder={"Provider's address, including city & state"} onChange={(e)=>{
              setFacilitiesGroup(
                
                {...facilitiesGroup, [`customFacAddress${setIndex}`]: e.target.value}
                
                ); 
              }}
            /> 
          </div>
    )
}