import React from "react";
import Popover from "../../../../formik/refactorOut/components/Popper/Popover";
import { popoverLocation } from '../../../../config/Configuration'
import Required from "../../../components/Required";
import Markdown from "markdown-to-jsx";
import PBV_icon from '../../../assets/pbv.png'

const ReleaseDatesSection = ({ formik, inputClasses, template, config, supportingDocsSection, dirty}) => {

  return (
    config.page.form.sections.releaseDatesSection.active ?
      <div id='releaseDatesSection'>
        <div id='disclaimer-and-submitButton'>
          <div id='release-disclaimer-container'>
            {/* <label style={template.styles.label}>
              {supportingDocsSection.fields.disclaimers.header} <Required value={true} />
            </label> */}
            <Markdown className="disclaimer-text" style={template.styles.label}>
              {supportingDocsSection.fields.disclaimers.emailNotice}
            </Markdown>
            <br/> <br/>
            <Markdown className="disclaimer-text">
              {supportingDocsSection.fields.disclaimers.requestNotice}
            </Markdown>
          </div>



        <div id='date-and-submit-container'>
          <div className="form-field release-date-field">
            <label htmlFor="releaseFromDate">
              <span style={template.styles.label}>{template.fields.todaysDate.label}</span>{" "}
            </label>
            <div>
              <div>
                <input
                  disabled
                  {...formik.getFieldProps("todaysDate")}
                  name="todaysDate"
                  placeholder={template.fields.todaysDate.hint}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div id='submit-button-section' className='form-section'>
            <button disabled={!dirty} style={{...config.page.form.styles.buttons.act, opacity: !dirty === true && .5}} id='submit-button' type="submit" > {config.page.form.components.submitButton.text} </button>
          </div>
        </div>
        </div>
        <img src={PBV_icon} alt="Powered by Verisma" className='pbv-icon'/>
      </div>
      :
      <></>
  );
};

export default ReleaseDatesSection;
