import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { Consumer } from './context';

const Target = ({ children: element }) => (
  <Consumer>
    {({ setTargetNode, togglePopover, onMouseEnterOnTarget, onMouseLeaveFromTarget }) =>
      cloneElement(element, {
        ref: setTargetNode,
        onMouseEnter: onMouseEnterOnTarget,
        onMouseLeave: onMouseLeaveFromTarget,
      })
    }
  </Consumer>
);

Target.propTypes = {
  children: PropTypes.element,
};

export default Target;
