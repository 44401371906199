import { patientInformationValidations } from "./patientInformationValidations";
import { authorizationToReleaseValidations } from "./authorizationToReleaseValidations";
import { informationToReleaseValidations } from "./informationToReleaseValidations";
import { deliveryMethodValidations } from "./deliveryMethodValidations";
import { purposeOfDisclosureValidations } from "./purposeOfDisclosureValidations";
import { discloseToValidations } from "./discloseToValidations";
import { formLevelValidations } from "./formLevelValidations";
import {supportingDocsValidations} from './supportingDocsValidations';
import {caseNumberValidations} from "./caseNumberValidations"
import {repContactInfoValidations} from './repContactInfoValidations';
import {additionalInfoValidations} from './additionalInfoValidations';

export const allValidations = data => {
  const {
    patientInformationSection,
    authorizationToReleaseSection,
    supportingDocsSection,
    discloseToSection,
    informationToReleaseSection,
    deliveryMethodSection,
    purposeOfDisclosureSection,
    caseNumberSection,
    repContactInfoSection,
    additionalInfoSection
  } = data.page.form.sections;

  // console.log(repContactInfoSection)

  return patientInformationValidations(patientInformationSection)
    .concat(authorizationToReleaseValidations(authorizationToReleaseSection, data))
    .concat(discloseToValidations(discloseToSection))
    .concat(informationToReleaseValidations(informationToReleaseSection))
    .concat(deliveryMethodValidations(deliveryMethodSection))
    .concat(purposeOfDisclosureValidations(purposeOfDisclosureSection))
    .concat(supportingDocsValidations(supportingDocsSection))
    .concat(formLevelValidations(data.page.form))
    .concat(caseNumberValidations(repContactInfoSection.fields.caseNumber))
    .concat(repContactInfoValidations(repContactInfoSection))
    .concat(additionalInfoValidations(additionalInfoSection));
};
