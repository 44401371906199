import React, {useState} from "react";
import Required from "../../../../components/Required";
// import Checkbox from "../../../components/Checkbox";
import { Field } from "formik";
import RadioButtonGroup from "../../../../components/RadioButtonGroup";
import RadioButton from "../../../../components/RadioButton";

const Facilities_7224 = ({ formik, template, config, orgId }) => {

    // const [otherFac, setOtherFac] = useState(null)

  // console.log(formik.getFieldProps("facilitiesGroup"))
  // console.log(formik.values)

  return (
    <div id='medicalFacilitiesRequested' className='form-section'>
      <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
      <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>
      
      <div id='7224'>
        {/* ^^ special behavior for Temple */}
          <RadioButtonGroup id="rep-radio-options" value={formik.values.facilitiesGroup} error={formik.errors.facilitiesGroup} touched={formik.touched.facilitiesGroup} onBlur={formik.setFieldTouched} >
            <span id="rep-radio-options">
              {template.fields.facilitiesGroup.entries.map(option => {
                return (
                    <Field 
                    config={config} 
                    key={option.value} 
                    component={RadioButton} 
                    name="facilitiesGroup" 
                    id={option.key} 
                    checked={formik.values.facilitiesGroup === [option.key] ? true : console.log(formik.values.facilitiesGroup)} 
                    value={option.key} 
                    label={(option.key !== 'otherFac') ? option.value 
                    : 
                    <div className="form-group" style={{ marginBottom: 0, alignSelf: 'flex-start', width: 430 }}>
                    <input
                      {...formik.getFieldProps("otherFac")}
                      style={{width:'40%'}}
                      type="text"
                      maxLength={
                        template.fields.otherFac.validations.maxLength.value
                      }
                      name="otherFac"
                      id="otherFac"
                      className="form-control"
                      style={config.page.form.styles.hint}
                      placeholder={template.fields.otherFac.hint}
                    />
                    {formik.touched.otherFac &&
                      formik.errors.otherFac ? (
                        <span className="input-error-text">
                          {formik.errors.otherFac}
                        </span>
                      ) : null}
                </div>} />
                  )  
              } )}
            </span>
          </RadioButtonGroup>
        </div>
              
    </div>
  );
};

export default Facilities_7224;
