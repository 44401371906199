import React from 'react'

export const FillFormButton = ({ formik }) => {
    return (
        <button onClick={() => {
            formik.values.discloseToPhone = '123-456-7890';
            formik.values.discloseToEmail = 'test@test.com';
            formik.values.discloseToFax = '1234567890';
            formik.values.discloseToFName = 'test';
            formik.values.discloseToMName = 't';
            formik.values.discloseToLName = 'test';
            formik.values.discloseToOrgName = 'test';
            formik.values.discloseToAddressLine1 = 'test';
            formik.values.discloseToAddressLine2 = 'test';
            formik.values.discloseToCity = 'test';
            formik.values.discloseToState = 'TE';
            formik.values.discloseToZip = '00000';
            formik.values.repRole = '2';
            formik.values.patientInfoFirstName = 'test';
            formik.values.patientInfoMiddleInitial = 't';
            formik.values.patientInfoLastName = 'test';
            // formik.values.patientInfoOtherName = 'test';
            formik.values.patientInfoDob = new Date(2000, 11, 24, 10, 33, 30, 0);
            formik.values.infoToReleaseGroup = ['test'];
            formik.values.releaseFromDate = new Date(2020, 11, 24, 10, 33, 30, 0);
            formik.values.releaseToDate = new Date(2020, 11, 25, 10, 33, 30, 0);
            formik.values.supportingDoc = [{ lastModified: 1626787691104, lastModifiedDate: new Date(2021, 11, 24, 10, 33, 30, 0), name: "Rectangle 423.pdf", size: 858, type: "application/pdf", webkitRelativePath: "" }];
            formik.values.facilitiesGroup = ['test1', 'test2'];
            formik.values.certBox = false;
            // formik.values.repDesc = 'test';
            // formik.values.supportingDoc = 'test';
            // console.log(formik.values)
        }}>Fill form </button>
    )
}
