import React, {useEffect, useState, useCallback} from "react";
import Required from "../../../../components/Required";
import Checkbox from "../../../../components/Checkbox";
import { DisabledCheckbox } from '../7042/components'
import {FacilityItem} from '../../sections/AuthorizationToReleaseSection'
import './FacilitiesSection.scss'

const THRFacilitySection = ({ formik, inputClasses, props, template, config }) => {
    
    // useEffect(()=>{
    //   !formik.values.facilitiesGroup.includes('Facility1') && formik.setFieldValue('facilitiesGroup', ['Facility1'])
    //   console.log(formik.values)
    // }, [formik.values.facilitiesGroup])
    
    return (
        config.page.form.sections.authorizationToReleaseSection.active ?
          <div id='medicalFacilitiesRequested' className='form-section thr-form-section'>
            <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
            <label className='non-textbox-label'> <span style={template.styles.label}> {'I authorize'} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>
            <Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Facility1'} name="facilitiesGroup" label={'Texas Health'} value={'Facility1'} />
            <Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Facility2'} name="facilitiesGroup" label={'THPG Clinics'} value={'Facility2'} />
            <Checkbox style={template.styles.list} className="custom-form-check checkbox" key={'Facility3'} name="facilitiesGroup" label={'Texas Health Urgent Care'} value={'Facility3'} />
            <br />
            <FacilityItem item={{key: 'otherFac', value: 'Specific facility or provider at CBC Trial Works'}} template={template} formik={formik} config={config} disabled={false} key={54321}/>
          </div>
          :
          <></>
      );
};

export default THRFacilitySection;

