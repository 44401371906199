import React from "react";
import Markdown from 'markdown-to-jsx';
import Checkbox from "../../../components/Checkbox";
import Popover from "../../../../formik/refactorOut/components/Popper/Popover";
import { popoverLocation } from "../../../../config/Configuration";

const DisclosureOfSensitiveInformation = ({
  formik,
  inputClasses,
  template,
  config
}) => {
  const seperateOptions = item => item.value === 91 ? { marginTop: "20px" } : { marginTop: "0px" }

  return (
    config.page.form.sections.disclosureOfSensitiveInformation.active ?
      <div>
        <div className="row">
          <div className="col">
            <h2 style={template.styles.section} className="section-heading">
              {template.title}
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div style={template.styles.disclaimer} className="col">
              <Markdown>{template.disclaimer}</Markdown>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div style={{ marginBottom: 10 }}></div>
              <div style={{ padding: "0 0 10px 0", marginLeft: "2%" }}>
                {template.fields.sensitiveInfo.entries.map(item => (
                  <div style={seperateOptions(item)} key={item.value} >
                    <Checkbox
                      style={template.styles.list}
                      className="custom-form-check"
                      key={item.value}
                      name="sensitiveInfo"
                      label={item.label}
                      value={item.value}
                    />{" "}
                    <Popover placement={popoverLocation}>
                      <Popover.Target>
                        <i className="fa fa-info-circle fa_custom"></i>
                      </Popover.Target>
                      <Popover.Content>{item.tooltip}</Popover.Content>
                    </Popover>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <></>
  );
};

export default DisclosureOfSensitiveInformation;
