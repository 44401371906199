import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Target from './Target';
import Content from './Content';
import { Provider } from './context';

class Popover extends PureComponent {
  static propTypes = {
    placement: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  };

  static defaultProps = {
    placement: 'bottom',
  };

  static Target = Target;
  static Content = Content;

  state = {
    placement: this.props.placement,
    showPopover: false,
    targetNode: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.placement !== prevState.placement) {
      return { placement: nextProps.placement };
    }

    return null;
  }

  setTargetNode = node => this.setState({ targetNode: node });

  showPopover = () => this.setState({ showPopover: true });

  hidePopover = () => this.setState({ showPopover: false });

  render() {
    const context = {
      showPopover: this.state.showPopover,
      placement: this.state.placement,
      targetNode: this.state.targetNode,
      setTargetNode: this.setTargetNode,
      onMouseEnterOnTarget: this.showPopover,
      onMouseLeaveFromTarget: this.hidePopover,
    };
    return <Provider value={context}>{this.props.children}</Provider>
  }
}

export default Popover;
