import React from 'react';
import { connect } from 'formik';
class ErrorFocus extends React.Component {
    isObject(value) {
        return value && typeof value === 'object' && value.constructor === Object;
    }
    getKeysRecursively = (object) => {
        if (!this.isObject(object)) {
            return ''
        }
        const currentKey = Object.keys(object)[0]
        if (!this.getKeysRecursively(object[currentKey])) {
            return currentKey
        }
        return currentKey + "." + this.getKeysRecursively(object[currentKey])
    }
    componentDidUpdate(prevProps) {
        const { isSubmitting, isValidating, errors } = prevProps.formik;
        const keys = Object.keys(errors);
        // console.log(prevProps)
        if (keys.length > 0 && prevProps.errorToFocus) {
            const selectorKey = this.getKeysRecursively(errors)
            const selector = `[name="${selectorKey}"] `;
            
            const errorElement = document.querySelector(selector);
            if(errorElement){
                // console.log(selector)
                prevProps.setErrorToFocus(false);
                errorElement.focus();
            }
        }
    }
    render() {
        return null;
    }
}
export default connect(ErrorFocus);