import React, {useEffect, useState, useCallback} from "react";
import Required from "../../../../components/Required";
import Checkbox from "../../../../components/Checkbox";
import { DisabledCheckbox } from '../../orgs/7042/components'

const AlleghenyFacilitySection = ({ formik, inputClasses, props, template, config }) => {

    return (
        config.page.form.sections.authorizationToReleaseSection.active ?
          <div id='medicalFacilitiesRequested' className='form-section'>
            <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
            <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>
    
            <div id='ordinary-facilities' className='form-fields' style={{flexFlow: 'column wrap', maxHeight:250, marginBottom: 10}}>
              {template.fields.facilitiesGroup.entries.map((item, index) => {
                return (
                  (!item.type) && (item.key !== 'otherFac') &&
                    <div key={index} style={{marginBottom: 10}}>
                        <input className='radio-button' type="radio" name='facility' id={`facility${item.key}`} onChange={()=>{formik.setFieldValue('facilitiesGroup', [item.key])}}/> 
                        <label htmlFor={`facility${item.key}`} style={{fontWeight: 400}}>{item.value}</label>
                    </div>
                )
              })}
            </div>
            <input type="text" className='form-control' style={{width: '50%'}} placeholder='List any facilities, providers, or physicians in the Allegheny Health Network' maxLength='20' onChange={(e)=>{formik.setFieldValue('otherFac', e.target.value)}}/>
          </div>
          :
          <></>
      );
};

export default AlleghenyFacilitySection;