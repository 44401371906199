import * as Yup from "yup";

export const caseNumberValidations = section => {
// const fields = section.fields;
// console.log(section)
if(section.active){
    return Yup.object().shape({
        caseNumber: Yup.string()
        .max(
            section.validations.maxLength.value,
            section.validations.maxLength.message
            )
            .required(
                () =>
                section.validations.required
                    .value &&
                    section.validations.required
                    .message
              )
    });
}
}