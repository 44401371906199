import React, {useEffect, useState} from 'react';
import {getwayApi} from '../../../config/Constants';
import {ConfirmationPage} from './ConfirmationPage'
import axios from "axios";

export const ConfirmationPageContainer = ({session}) => {
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(true);

    const doFetch = async () => {  
        try {
          await axios
          .get(`${getwayApi}/api/session/conformation/${session.id}`)
            .then(res => {
              
              setConfig(res.data)
              setLoading(false) 
            })
            
            .catch(err => {
              console.log(err)   
            });
          } 
          
          catch (error) {
            console.log(error)  
        }
      };

      useEffect(() => {
        doFetch();
        
      },[]);

      

      
      return(
          <>
            {loading && <div>loading</div>}
            {/* {!loading && <ConfirmationPage session={session.id} config={JSON.parse(config.confirmSpecs)} textImage = {config.textImage} trackingLink={config.trackingLink} surveryLink={config.surveryLink} formId={config.formId}/>} */}
            {!loading && <ConfirmationPage session={session.id} config={JSON.parse(config.confirmSpecs)} textImage = {config.textImage} trackingLink={config.trackingLink} surveryLink={config.surveryLink} formId={config.formId}/>}
          </>
      )

      
}