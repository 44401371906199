export const mapErrorsToSection = (config, errors) => {
  // TODO; works, clean it up
  let display = [];

  const errorFields = Object.keys(errors);

  Object.values(config.page.form.sections)
    .filter(s => s.active)
    .forEach(s => {
      const output = { name: s.title, messages: [] };
      const fields = Object.keys(s.fields);

      fields.forEach(field => {
        if (errorFields.includes(field)) {
          output.messages.push(errors[field]);
        }
      });

      if (output.messages.length > 0)
        display.push(output);
    });

  return display;
};
