import React, {useEffect, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from "axios";
import {apiHost, getwayApi } from '../../../config/Constants';
import {LoadingScreen} from '../../components/LoadingScreen'
import './styles/css/confirmation.css';
import './styles/css/confirmation.css';
import copyIcon from './styles/assets/copy-icon.png';
import vzaLogo from '../form/styles/assets/verisma_logo.png';
import PBV from '../../assets/pbv.png'



export const ConfirmationPage = ({session, config, formId}) => {
    const [userData, setUserData] = useState(null);
    
    const doFetch = async () => {
        try {
          await axios
          .get(`${apiHost}/api/accessCode?session=${session}`)
            .then(res => {
              setUserData(res.data);         
            })
            .catch(err => {
                         
            });
          } catch (error) {
         
        }
      };
    useEffect(() => {
      doFetch();  
    },[]);
    
    
    
    // console.log(config);

    if(config && userData){
    
        

        return(
            
            <div id='confirmation-content-container' className='page'>
                
                <img id='confirmation-logo' 
                style={config.context.logo} 
                // style={{width:97, height:28.06, marginBottom: 37}} 
                src={`${getwayApi}/api/client/logo/${formId}`} 
                // src={vzaLogo} 

                />
                
                {/* =========================================================================================TITLE===================================================================================================== */}
                <h2 id='confirmation-headline' style={{fontFamily: config.context.title.styles.fontFamily, fontWeight: config.context.title.styles.fontWeight, color: config.context.title.styles.color, fontSize :config.context.title.styles.fontSize}}> {config.context.title.text}</h2>
                {/* =========================================================================================TITLE===================================================================================================== */}
                
                <div id='confirmation-paragraphs-container'>

                    {/* <h6 style={config.context.requestorName} >{userData.name},</h6> */}
                    
                    {/* <div id='' style={config.context.textBody.bodyStyle}> */}
                        
                        <p className='confirmation-p-text' style={config.context.textBody.bodyStyle}> {ReactHtmlParser(config.context.textBody.paragraphs.formSend.replace("(email address here)", userData.email))} </p>
                        
                        <p className='confirmation-p-text' style={config.context.textBody.bodyStyle}> {ReactHtmlParser(config.context.textBody.paragraphs.releaseSend)} </p>
                       
                        
                
                </div>

                <div id='confirmation-tracking-container'>
                    <p id='tracking-header' style={config.context.trackRequest.trackHeader.styles}>{config.context.trackRequest.trackHeader.text}</p>
                    {/* <div id='tracking-number-and-copy-icon'> */}
                        <p id='tracking-number' style={config.context.trackRequest.number.styles}>{userData.vrmAccessCode}</p>
                        {/* <img id='tracking-copy-icon' src={copyIcon} style={config.context.trackRequest.copyIcon.styles}></img> */}
                    {/* </div> */}
                    <div id='tracking-button' style={config.context.trackRequest.trackingButton.styles} onClick={()=>{window.open(`${config.context.trackRequest.trackingButton.trackingLink}${userData.vrmAccessCode}`, '_blank')}}>
                        <p id='tracking-button-text'>{config.context.trackRequest.trackingButton.text}</p>
                    </div>
                    <img src={PBV} className='conf-pbv-image' alt="Powered by Verisma" />
                </div>
                
                <div id='confirmation-feedback-container' style={config.context.survey.container.styles}>
                    <div id='confirmation-feedback-text-container'>
                        <h5 style={config.context.survey.titleStyle}>{config.context.survey.titleText}</h5>
                        {/* <p style={config.context.survey.bodyStyle}> {config.context.survey.bodyText} {ReactHtmlParser(config.context.survey.linkText.replace("(survey link)", userData.specProps.SurveryLink))}</p> */}
                        <a href='https://is.gd/vraasurvey' target="_blank" style={config.context.survey.bodyStyle}>{config.context.survey.bodyText}</a> 
                        
                        {/* <p style={config.context.survery.linkStyle}> {ReactHtmlParser(config.context.survery.linkText.replace("(survery link)", userData.specProps.SurveryLink))} </p> */}
                    
                    </div>
                </div>
            
            </div>
        );
    }else{
        return(
            <LoadingScreen loadingMessage={"Finalizing your request. We'll be just a moment."}/>
        )
    }
}