import * as Yup from "yup";
import {
  dateCantBeInFuture,
  dateIsBefore
} from "./validationFunctions";

export const informationToReleaseValidations = section => {
  if (section.active) {
    return Yup.object().shape({
      releaseFromDate: Yup.date()
        .required(
          () =>
            section.fields.releaseFromDate.validations.required.value &&
            section.fields.releaseFromDate.validations.required.message
        ).nullable()
        .max(
          dateCantBeInFuture,
          section.fields.releaseFromDate.validations.notFutureDate.message
        )
        .test(
          "releaseFromDate",
          section.fields.releaseFromDate.validations.notFutureDate.message,
          function(date){
            var todaysDate = new Date();
            var enteredDate = new Date(date);
            return todaysDate > enteredDate;
          }
        ),
      releaseToDate: Yup.date()
        .required(
          () =>
            section.fields.releaseToDate.validations.required.value &&
            section.fields.releaseToDate.validations.required.message
        ).nullable()
        .max(
          dateCantBeInFuture,
          section.fields.releaseToDate.validations.notFutureDate.message
        )
        .test(
          "afterFromDate",
          section.fields.releaseToDate.validations.notBeforeDate.message,
          function(releaseToDate) {
            const { releaseFromDate } = this.parent;
            if (releaseFromDate && releaseToDate) {
              return dateIsBefore(releaseFromDate, releaseToDate);
            }
          }
        )
        .test(
          "afterFromDate",
          section.fields.releaseToDate.validations.notFutureDate.message,
          function(date){
            var todaysDate = new Date();
            var enteredDate = new Date(date);
            return todaysDate > enteredDate;
          }
        ),
        infoToReleaseGroup: Yup.array()
          .when('infoToReleaseGroupOther', {
            is: (value) => !value || value.length === 0,
            then: Yup.array().required(section.fields.infoToReleaseGroup.validations.required.message)
          }),
          // .required(
          //   () =>
          //   section.fields.infoToReleaseGroup.validations.required.value &&
          //   section.fields.infoToReleaseGroup.validations.required.message
          // ),
      infoToReleaseGroupOther: Yup.string()
        .test(
          "infoToReleaseGroupOther",
          section.fields.infoToReleaseGroupOther.validations.required.message,
          function(item) {
           
            const { infoToReleaseGroup } = this.parent;
            if(infoToReleaseGroup.includes("otherItem"))
              return item && infoToReleaseGroup.includes("otherItem");
            else return true;
          }
        )
        .max(
          section.fields.infoToReleaseGroupOther.validations.maxLength
            .value,
          section.fields.infoToReleaseGroupOther.validations.maxLength
            .message
        )
    });
  }
}
