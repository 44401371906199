import React, { useEffect, useState } from 'react';
import { Field } from "formik";
import { RequestorMatchModal, RequestorSet, getMatchingRequestor } from '../selectorMatch/selectorMatch';
import Required from "../../../components/Required";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";
import Modal from "react-modal";
import axios from "axios";
import '../styles/css/repContactInfo.css'
import '../styles/css/popup.css'
import { ErrorMessage } from "formik";
import { FillFormButton } from '../../../../config/devComponents';

// import { apiHost } from "../../../config/Constants";


export const RepContactInfoSection = ({ formik, inputClasses, template, orgId, selectorMatch, config }) => {
  const [showRequestorModal, setShowRequestorModal] = useState(false);
  const [requestors, setRequestors] = useState([])
  const [selectUser, setSelectUser] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(false);

  const setRepContactInfo = (firstName, LastName, email, phone, address, city, zipCode, state, orgName, address2, fax, requestorNameId, requestorContactId) => {
    // console.log(`${firstName} firstname`)
    if (typeof email == "string" && email.length > 0) formik.setFieldValue("discloseToEmail", email);
    if (typeof phone == "string" && phone.length > 0) formik.setFieldValue("discloseToPhone", phone);
    if (typeof firstName == "string" && firstName.length > 0) formik.setFieldValue("discloseToFName", firstName);
    if (typeof LastName == "string" && LastName.length > 0) formik.setFieldValue("discloseToLName", LastName);
    if (typeof orgName == "string" && orgName.length > 0) formik.setFieldValue("discloseToOrgName", orgName);
    if (typeof address == "string" && address.length > 0) formik.setFieldValue("discloseToAddressLine1", address);
    if (typeof address2 == "string" && address2.length > 0) formik.setFieldValue("discloseToAddressLine2", address2);
    if (typeof city == "string" && city.length > 0) formik.setFieldValue("discloseToCity", city);
    if (typeof state == "string" && state.length > 0) formik.setFieldValue("discloseToState", state);
    if (typeof zipCode == "string" && zipCode.length > 0) formik.setFieldValue("discloseToZip", zipCode);
    if (typeof fax == "string" && fax.length > 0) formik.setFieldValue("discloseToFax", fax);
    if (typeof requestorNameId == "number" && requestorNameId > 0) formik.setFieldValue("requestorNameId", requestorNameId);
    if (typeof requestorContactId == "number" && requestorContactId > 0) formik.setFieldValue("requestorContactId", requestorContactId);
  }


  const phoneFaxFormat = (e) => {
    (e.which > 57 || e.which < 48) && e.preventDefault()
    if (e.target.value.length === 3 || e.target.value.length === 7) {
      e.target.value = e.target.value.concat('-')
    }
    if (e.target.value.length === 12) {
      e.target.value = e.target.value.concat(' x')
    }
  }
  const faxFormat = (e) => {
    (e.which > 57 || e.which < 48) && e.preventDefault()
    if (e.target.value.length === 3 || e.target.value.length === 7) {
      e.target.value = e.target.value.concat('-')
    }
    // if (e.target.value.length === 12) {
    //   e.target.value = e.target.value.concat(' x')
    // }
  }

  useEffect(()=>{
    const phoneFields = ['discloseToPhone', 'discloseToFax']
    phoneFields.forEach((field) => {
      formik.errors[field] && formik.errors[field].includes('number is not valid') && formik.setFieldValue(field, formik.values[field].replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3"))
    })
  }, [formik.errors.discloseToPhone, formik.errors.Patient_Phone_Conform, formik.errors.RepPhone, formik.errors.Rep_Phone_Conform])



  useEffect(() => {
    if ((formik.touched.discloseToPhone && !formik.errors.discloseToPhone) && (formik.touched.discloseToEmail && !formik.errors.discloseToEmail)) {
      // If both Phone & Email have been focused, and the inputs result in no errors, call for requestors.
      getMatchingRequestor(orgId, formik.getFieldProps("discloseToEmail").value, formik.getFieldProps("discloseToPhone").value, setRequestors, setShowRequestorModal, setRepContactInfo, formik)()
      //this function internally calls showRequestorModal() which pulls up Modal.
    }

  }, [formik.touched.discloseToPhone, formik.errors.discloseToPhone, formik.touched.discloseToEmail, formik.errors.discloseToEmail]
  )

  return (
    config.page.form.sections.repContactInfoSection.active ?
      <div id='repContactInfoSection' className='form-section'>
        {showRequestorModal && RequestorMatchModal(showRequestorModal, setShowRequestorModal, template, requestors, setRepContactInfo, selectUser, setSelectUser, selectedItem, setSelectedItem, selectorMatch, config)}

        <h2
          style={config.page.form.styles.section}
          className="section-heading">
          {template.title}
        </h2>

        <div className="form-fields">

          <div className="form-field phone-field">
            <label htmlFor="discloseToPhone">
              {" "}
              <span style={template.styles.label}>
                {template.fields.discloseToPhone.label}{" "}
              </span>
              <Required
                value={template.fields.discloseToPhone.validations.required}
              />
            </label>
            <input
              {...formik.getFieldProps("discloseToPhone")}
              type="tel"
              style={config.page.form.styles.hint}
              size={30}
              name="discloseToPhone"
              onFocus={() => { formik.touched.discloseToPhone = false; setTimeout(() => console.log(formik), 3000) }}
              onKeyPress={(e) => { phoneFaxFormat(e) }}
              maxLength={template.fields.discloseToPhone.validations.maxLength.value}
              className={inputClasses(
                formik.touched.discloseToPhone &&
                formik.errors.discloseToPhone
              )}
              placeholder={template.fields.discloseToPhone.hint}
            />

            {formik.touched.discloseToPhone &&
              formik.errors.discloseToPhone && (
                <span className="input-error-text">
                  {formik.errors.discloseToPhone}
                </span>
              )}
          </div>

          <div className="form-field email-field">
            <label htmlFor="discloseToEmail">
              <span style={template.styles.label}>
                {template.fields.discloseToEmail.label}
                {" "}
                <Required value={template.fields.discloseToEmail.validations.required.value} />
              </span>{" "}
            </label>
            <input
              {...formik.getFieldProps("discloseToEmail")}
              type="text"
              name="discloseToEmail"
              onFocus={() => { formik.touched.discloseToEmail = false; setTimeout(() => console.log(formik), 3000) }}
              style={config.page.form.styles.hint}
              className={inputClasses(formik.touched.discloseToEmail && formik.errors.discloseToEmail)}
              maxLength={template.fields.discloseToEmail.validations.maxLength.value}
              placeholder={template.fields.discloseToEmail.hint}
            />
            {formik.touched.discloseToEmail &&
              formik.errors.discloseToEmail && (
                <span className="input-error-text">
                  {formik.errors.discloseToEmail}
                </span>
              )}
          </div>

          <div className="form-field fax-field">
            <label htmlFor="discloseToFax">
              {" "}
              <span style={template.styles.label}>
                {template.fields.discloseToFax.label}{" "}
              </span>
              {/* <Required
                value={template.fields.discloseToFax.validations.required}
              /> */}
            </label>
            <input
              {...formik.getFieldProps("discloseToFax")}
              type="tel"
              style={config.page.form.styles.hint}
              size={30}
              name="discloseToFax"
              onKeyPress={(e) => { faxFormat(e) }}
              // maxLength={template.fields.discloseToFax.validations.maxLength.value}
              maxLength={12}
              className={inputClasses(
                formik.touched.discloseToFax &&
                formik.errors.discloseToFax
              )}
              placeholder={template.fields.discloseToFax.hint}
            />

            {formik.touched.discloseToFax &&
              formik.errors.discloseToFax && (
                <span className="input-error-text">
                  {formik.errors.discloseToFax}
                </span>
              )}
          </div>

          <div className="form-field firstName-field">
            <label htmlFor="discloseToFName">
              {" "}
              <span style={template.styles.label}>
                {template.fields.discloseToFName.label}
              </span>{" "}
              <Required
                value={
                  template.fields.discloseToFName.validations.required.value
                }
              />
            </label>
            <input
              {...formik.getFieldProps("discloseToFName")}
              type="text"
              name="discloseToFName"
              style={config.page.form.styles.hint}
              maxLength={template.fields.discloseToFName.validations.maxLength.value}
              className={inputClasses(
                formik.touched.discloseToFName &&
                formik.errors.discloseToFName
              )}
              placeholder={template.fields.discloseToFName.hint}
            />
            {formik.touched.discloseToFName &&
              formik.errors.discloseToFName && (
                <span className="input-error-text">
                  {formik.errors.discloseToFName}
                </span>
              )}
          </div>

          <div className="form-field MI-field">
            {/* <div className="form-group"> */}
            <label htmlFor="discloseToMName">
              {" "}
              <span style={template.styles.label}>
                {template.fields.discloseToMName.label}
              </span>{" "}

            </label>
            <input
              {...formik.getFieldProps("discloseToMName")}
              type="text"
              name="discloseToMName"
              style={config.page.form.styles.hint}
              className={inputClasses(
                formik.touched.discloseToMName &&
                formik.errors.discloseToMName
              )}
              maxLength={template.fields.discloseToMName.validations.maxLength.value}
              placeholder={template.fields.discloseToMName.hint}
            />
            {formik.touched.discloseToMName &&
              formik.errors.discloseToMName && (
                <span className="input-error-text">
                  {formik.errors.discloseToMName}
                </span>
              )}
            {/* </div> */}
          </div>

          <div className="form-field lastName-field">
            <label htmlFor="discloseToLName">
              {" "}
              <span style={template.styles.label}>
                {template.fields.discloseToLName.label}
              </span>{" "}
              <Required
                value={
                  template.fields.discloseToLName.validations.required.value
                }
              />
            </label>
            <input
              {...formik.getFieldProps("discloseToLName")}
              type="text"
              name="discloseToLName"
              maxLength={template.fields.discloseToLName.validations.maxLength.value}
              style={config.page.form.styles.hint}
              className={inputClasses(
                formik.touched.discloseToLName &&
                formik.errors.discloseToLName
              )}
              placeholder={template.fields.discloseToLName.hint}
            />
            {formik.touched.discloseToLName &&
              formik.errors.discloseToLName && (
                <span className="input-error-text">
                  {formik.errors.discloseToLName}
                </span>
              )}
          </div>

          <div className="form-field org-name-field">
            <label htmlFor="discloseToOrgName">
              {" "}
              <span style={template.styles.label}>
                {template.fields.discloseToOrgName.label}
              </span>{" "}
              <Required
                value={template.fields.discloseToOrgName.validations.required}
              />
            </label>
            <input
              {...formik.getFieldProps("discloseToOrgName")}
              type="text"
              name="discloseToOrgName"
              style={config.page.form.styles.hint}
              maxLength={template.fields.discloseToOrgName.validations.maxLength.value}
              className={inputClasses(
                formik.touched.discloseToOrgName &&
                formik.errors.discloseToOrgName
              )}
              placeholder={template.fields.discloseToOrgName.hint}
            />
            {formik.touched.discloseToOrgName &&
              formik.errors.discloseToOrgName && (
                <span className="input-error-text">
                  {formik.errors.discloseToOrgName}
                </span>
              )}
          </div>

          <div className='address-container'>
            <div className="form-field org-address1-field">
              <label htmlFor="discloseToAddressLine1">
                <span style={template.styles.label}>
                  {template.fields.discloseToAddressLine1.label}
                </span>{" "}
                <Required
                  value={
                    template.fields.discloseToAddressLine1.validations
                      .required
                  }
                />
              </label>
              <input
                {...formik.getFieldProps("discloseToAddressLine1")}
                type="text"
                maxLength={
                  template.fields.discloseToAddressLine1.validations.maxLength
                    .value
                }
                name="discloseToAddressLine1"
                style={config.page.form.styles.hint}
                id="discloseToAddressLine1"
                maxLength={template.fields.discloseToAddressLine1.validations.maxLength.value}
                className={inputClasses(
                  formik.touched.discloseToAddressLine1 &&
                  formik.errors.discloseToAddressLine1
                )}
                placeholder={template.fields.discloseToAddressLine1.hint}
              />
              {formik.touched.discloseToAddressLine1 &&
                formik.errors.discloseToAddressLine1 && (
                  <span className="input-error-text">
                    {formik.errors.discloseToAddressLine1}
                  </span>
                )}
            </div>




            <div className="form-field org-address2-field">
              {template.fields.discloseToAddressLine2.label && (
                <label htmlFor="discloseToAddressLine2">
                  <span style={template.styles.label}>
                    {template.fields.discloseToAddressLine2.label}
                  </span>{" "}
                  <Required
                    value={
                      template.fields.discloseToAddressLine2.validations
                        .required.value
                    }
                  />
                </label>
              )}
              <input
                {...formik.getFieldProps("discloseToAddressLine2")}
                type="text"
                maxLength={template.fields.discloseToAddressLine2.validations.maxLength.value}
                maxLength={
                  template.fields.discloseToAddressLine2.validations.maxLength
                    .value
                }
                name="discloseToAddressLine2"
                id="discloseToAddressLine2"
                style={config.page.form.styles.hint}
                className={inputClasses(
                  formik.touched.discloseToAddressLine2 &&
                  formik.errors.discloseToAddressLine2
                )}
                placeholder={template.fields.discloseToAddressLine2.hint}
              />
              {formik.touched.discloseToAddressLine2 &&
                formik.errors.discloseToAddressLine2 && (
                  <span className="input-error-text">
                    {formik.errors.discloseToAddressLine2}
                  </span>
                )}
            </div>
          </div>

          <div className="form-field city-field">
            {template.fields.discloseToCity.label && (
              <label htmlFor="discloseToCity">
                <span style={template.styles.label}>
                  {template.fields.discloseToCity.label}
                </span>{" "}
                <Required
                  value={
                    template.fields.discloseToCity.validations.required
                      .value
                  }
                />
              </label>
            )}
            <input
              {...formik.getFieldProps("discloseToCity")}
              type="text"
              name="discloseToCity"
              style={config.page.form.styles.hint}
              className={inputClasses(
                formik.touched.discloseToPhone && formik.errors.discloseCity
              )}
              maxLength={
                template.fields.discloseToCity.validations.maxLength.value
              }
              onKeyPress={(e)=>{
                (!e.key.match(/[a-zA-Z]/)) && e.preventDefault()
              }}
              placeholder={template.fields.discloseToCity.hint}
            />
            {formik.touched.discloseToCity &&
              formik.errors.discloseToCity && (
                <span className="input-error-text">
                  {formik.errors.discloseToCity}
                </span>
              )}
          </div>

          <div className="form-field state-field">
            <div className="form-group">
              {template.fields.discloseToState.label && (
                <label htmlFor="discloseToState">
                  <span style={template.styles.label}>
                    {template.fields.discloseToState.label}
                  </span>{" "}
                  <Required
                    value={
                      template.fields.discloseToState.validations.required
                        .value
                    }
                  />
                </label>
              )}
              <input
                {...formik.getFieldProps("discloseToState")}
                type="text"
                name="discloseToState"
                style={config.page.form.styles.hint}
                className={inputClasses(
                  formik.touched.discloseToPhone &&
                  formik.errors.discloseState
                )}
                maxLength={
                  template.fields.discloseToState.validations.maxLength.value
                }
                onKeyPress={(e)=>{
                  (!e.key.match(/[a-zA-Z]/)) && e.preventDefault()
                }}
                placeholder={template.fields.discloseToState.hint}
              />
              {formik.touched.discloseToState &&
                formik.errors.discloseToState && (
                  <span className="input-error-text">
                    {formik.errors.discloseToState}
                  </span>
                )}
            </div>
          </div>

          <div className="form-field zip-field">
            <div className="form-group">
              {template.fields.discloseToZip.label && (
                <label htmlFor="discloseToZip">
                  <span style={template.styles.label}>
                    {template.fields.discloseToZip.label}
                  </span>{" "}
                  <Required
                    value={
                      template.fields.discloseToZip.validations.required.value
                    }
                  />
                </label>
              )}
              <input
                {...formik.getFieldProps("discloseToZip")}
                type="text"
                name="discloseToZip"
                style={config.page.form.styles.hint}
                maxLength={template.fields.discloseToZip.validations.maxLength.value}
                onKeyPress={(e)=>{
                  (isNaN(Number(e.key))) && e.preventDefault()
                  if (e.target.value.length === 5) {
                    e.target.value = e.target.value.concat('-')
                  }
                }}
                className={inputClasses(
                  formik.touched.discloseToZip && formik.errors.discloseToZip
                )}
                placeholder={template.fields.discloseToZip.hint}
              />
              {formik.touched.discloseToZip &&
                formik.errors.discloseToZip && (
                  <span className="input-error-text">
                    {formik.errors.discloseToZip}
                  </span>
                )}
            </div>








          </div>

          <div id='rep-and-caseNumber-container'>
            <div className="form-field rep-question-field">
              <label htmlFor="repDesc" className='non-textbox-label'>
                {" "}
                <span style={template.styles.label}>
                  {template.fields.repRole.label}
                </span>{" "}
                <Required value={template.fields.repRole.validations.required.value} />
              </label>

              <RadioButtonGroup
                id="rep-radio-options"
                value={formik.values.repRole}
                error={formik.errors.repRole}
                touched={formik.touched.repRole}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
              >
                <span id="rep-radio-options">
                  {template.fields.repRole.entries.map((option, index) => (
                    // <span
                    //     style={template.styles.list}
                    //     key={option.value}
                    //     className="form-check"
                    // >
                    <Field
                      config={config}
                      key={index}
                      component={RadioButton}
                      name="repRole"
                      id={option.value}
                      checked={formik.getFieldProps("repRole").value == option.value ? true : false}
                      value={option.value}
                      label={option.label}
                    />
                    // </span>
                  ))}
                </span>
              </RadioButtonGroup>
            </div>

            <div className="form-field caseNumber-field">
              <label htmlFor="caseNumber">
                {" "}
                <span style={template.styles.label}>
                  {template.fields.caseNumber.label}
                </span>{" "}
                <Required
                  value={
                    template.fields.caseNumber.validations.required.value
                  }
                />
              </label>
              <input
                {...formik.getFieldProps("caseNumber")}
                type="text"
                name="caseNumber"
                style={config.page.form.styles.hint}
                maxLength={template.fields.caseNumber.validations.maxLength.value}
                className={inputClasses(
                  formik.touched.caseNumber &&
                  formik.errors.caseNumber
                )}
                placeholder={template.fields.caseNumber.hint}
              />
              {/* {formik.touched.caseNumber &&
                formik.errors.caseNumber && (
                  <span className="input-error-text">
                    {formik.errors.caseNumber}
                  </span>
                )} */}
            </div>
          </div>


        </div>


      </div>
      :
      <></>
  )
}