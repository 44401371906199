import React from 'react';
import { Field } from "formik";
// import Required from "../Required";
// import RadioButtonGroup from "../RadioButtonGroup";
// import RadioButton from "../RadioButton";


export const AdditionalInfoSection = ({ formik, template, inputClasses, config }) => {
    return (
        config.page.form.sections.additionalInfoSection.active ?
            <div id='additionalInfoSection' className='form-section'>


                <h2
                    style={template.styles.section}
                    className="section-heading">
                    {template.title}
                </h2>

                <div className='form-fields'>

                    <div className="form-field additionalInfo-textbox">
                        <label htmlFor="repDesc" className='non-textbox-label'>
                            {" "}
                            <span style={template.styles.label}>
                                {template.fields.repDesc.label}
                            </span>{" "}

                        </label>
                        <textarea
                            {...formik.getFieldProps("repDesc")}
                            name="repDesc"
                            style={template.styles.hint}
                            className={inputClasses(
                                formik.touched.repDesc &&
                                formik.errors.repDesc
                            )}
                            placeholder={template.fields.repDesc.hint}
                        />
                        {formik.touched.repDesc &&
                            formik.errors.repDesc && (
                                <span className="input-error-text">
                                    {formik.errors.repDesc}
                                </span>
                            )}
                    </div>

                </div>
            </div>
            :
            <></>
    )
}