import * as moment from "moment";

const phoneVals = {
    noDash: /^\d\d\d\d\d\d\d\d\d\d$/,
    noDash1: /^\d\d\d\d\d\d\d\d\d\d\sx\d$/,
    noDash2: /^\d\d\d\d\d\d\d\d\d\d\sx\d\d$/,
    noDash3: /^\d\d\d\d\d\d\d\d\d\d\sx\d\d\d$/,
    
    dash: /^\d\d\d-\d\d\d-\d\d\d\d$/,
    dash1: /^\d\d\d-\d\d\d-\d\d\d\d\sx\d$/,
    dash2: /^\d\d\d-\d\d\d-\d\d\d\d\sx\d\d$/,
    dash3: /^\d\d\d-\d\d\d-\d\d\d\d\sx\d\d\d$/,
}

// export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const phoneRegex = new RegExp(phoneVals.dash.source + '|' + phoneVals.dash1.source + '|' +  phoneVals.dash2.source + '|' +  phoneVals.dash3.source);
export const faxRegex = new RegExp(phoneVals.dash.source + '|' + phoneVals.dash1.source + '|' +  phoneVals.dash2.source + '|' +  phoneVals.dash3.source);
export const zipRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
export const dateCantBeInFuture = moment().add(0, "days");
export const minimumYearsOld = (years) => moment().add(years, "years");
export const maxYearAgo = years => moment().subtract(years, "years");
export const dateIsBefore = (before, after) => moment(before).isSameOrBefore(after);


// Acceptable phone formats
// 1234567890
// 1234567890 x1
// 1234567890 x12
// 1234567890 x123

// 123-456-7890
// 123-456-7890 x1
// 123-456-7890 x12
// 123-456-7890 x123
