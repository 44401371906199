import * as Yup from "yup";

export const authorizationToReleaseValidations = (section, formSpec) => {
  console.log(formSpec)
  
  if (section.active) {
    return Yup.object().shape({    
      
      facilitiesGroup: Yup.string().when("physiciansGroup", {
        is: val => val.length === 0,
        then: Yup.string().required(
          section.fields.facilitiesGroup.validations.required
            .message
        )
      }),

      facilitySelected: (formSpec.context.organization.includes('7229') || formSpec.context.organization.includes('7342') || formSpec.context.organization.includes('7307')) && Yup.string()
      .required(
        () =>
          true &&
          'Please provide the name and address of at least one medical provider'
      ),
      
      allOrNameGroup: Yup.string().when("physiciansGroup", {
        is: val => val.length === 1,
        then: Yup.string().required(
          section.fields.allOrNameGroup.validations.required
            .message
        )
      }),
      namedPhysicians: Yup.string().when("allOrNameGroup", {
        is: val => val === "namePhysicians",
        then: Yup.string().required(
          section.fields.namedPhysicians.validations.required
            .message
        )
      }),

      otherFac: Yup.string()
        .required(
          () =>
          formSpec.context.organization.includes('7451') &&
            'Please provide the name of a facility in the text box'
        )
        .test(
          "otherFac",
          section.fields.otherFac.validations.required.message,
          function(item) {
           
            const facs  = this.parent.facilitiesGroup.split(',');
            if(facs.includes("otherFac"))
              return item && facs.includes("otherFac");
            else return facs.length > 0;
          }
        )
        .max(
          section.fields.otherFac.validations.maxLength
            .value,
          section.fields.otherFac.validations.maxLength
            .message
        )
    });
  }
};
