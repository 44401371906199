import React, {useState} from 'react';
import Required from "../../../../components/Required";
import Checkbox from "../../../../components/Checkbox";
import { Field } from "formik";
// import RadioButtonGroup from "../../RadioButtonGroup";
// import RadioButton from "../../RadioButton";
import './styles/facilities.css'
import infoCircle from '../../styles/assets/info-circle.png'
import Popover from '../../../../../formik/refactorOut/components/Popper/Popover.js'
import { popoverLocation } from "../../../../../config/Configuration";

const Facilities_7227 = ({ formik, template, config, orgId }) => {
    const [outPatient, setOutPatient] = useState(false)
    const [longTerm, setLongTerm] = useState(false)

    return (
        <>
            <div id='medicalFacilitiesRequested' className='form-section'>
                <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
                <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>
                
                <div className='facility-options'>
                    
                    <div className='default-facility'>
                        <DecorativeCheckbox checked={true} onChange={null} labelStyle={template.styles.list} label={template.defaultFacility}/>
                    </div>
                    
                    <div className='additional-facilities'>
                        <p style={{marginBottom:10, fontWeight:600, textDecoration:'underline'}}>Additional Locations:</p>
                        <p>Please select any additional clinic locations by selecting the checkboxes below.</p>
                        
                        <div className='facility-list additional-facility-list'>
                            {template.fields.facilitiesGroup.entries.map((facility)=>{
                                return <span style={{ margin: 10, display: 'flex', alignItems: 'center' }}><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={facility.value} name="facilitiesGroup" label={(facility.key === 'otherFac') ? "" : facility.value} value={facility.key} />
                                    <Popover placement={popoverLocation}>
                                        <Popover.Target>
                                            {/* <i className="fa fa-info-circle fa_custom"></i> */}
                                            <img className='info-circle' src={infoCircle}></img>
                                        </Popover.Target>
                                        <Popover.Content>
                                            {facility.tooltip}
                                        </Popover.Content>
                                    </Popover>
                                </span>
                            })}
                        </div>
                        
                        <DecorativeCheckbox checked={outPatient && true} onChange={()=>{!outPatient ? setOutPatient(true) : setOutPatient(false)}} labelStyle={template.styles.list} label={template.fields.outPatientGroup.label}/>
                        <div className='facility-list'>
                            {outPatient && template.fields.outPatientGroup.entries.map((facility)=>{
                                return <span style={{ margin: 10, display: 'flex', alignItems: 'center' }}><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={facility.value} name="facilitiesGroup" label={(facility.key === 'otherFac') ? "" : facility.value} value={facility.key} />
                                    <Popover placement={popoverLocation}>
                                        <Popover.Target>
                                            {/* <i className="fa fa-info-circle fa_custom"></i> */}
                                            <img className='info-circle' src={infoCircle}></img>
                                        </Popover.Target>
                                        <Popover.Content>
                                            {facility.tooltip}
                                        </Popover.Content>
                                    </Popover></span>
                            })}
                        </div>
                        
                        <div className='facility-list'>
                            <DecorativeCheckbox checked={longTerm && true} onChange={()=>{!longTerm ? setLongTerm(true) : setLongTerm(false)}} labelStyle={template.styles.list} label={template.fields.longTermGroup.label}/>
                            {longTerm && template.fields.longTermGroup.entries.map((facility)=>{
                                return <span style={{ margin: 10, display: 'flex', alignItems: 'center' }}><Checkbox style={template.styles.list} className="custom-form-check checkbox" key={facility.value} name="facilitiesGroup" label={(facility.key === 'otherFac') ? "" : facility.value} value={facility.key} />
                                    <Popover placement={popoverLocation}>
                                        <Popover.Target>
                                            {/* <i className="fa fa-info-circle fa_custom"></i> */}
                                            <img className='info-circle' src={infoCircle}></img>
                                        </Popover.Target>
                                        <Popover.Content>
                                            {facility.tooltip}
                                        </Popover.Content>
                                    </Popover>
                                </span>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Facilities_7227;

// const DropdownCheckSection = () => {
//     return(
//         <DecorativeCheckbox checked={longTerm && true} onChange={()=>{!longTerm ? setLongTerm(true) : setLongTerm(false)}} labelStyle={template.styles.list} label={template.fields.longTermGroup.label}/>
//     )
// }

const DecorativeCheckbox = (props) => {
    
    return (
        <div className='decorative-checkbox'>
            <input type="checkbox" className='7227-checkbox checkbox' checked={props.checked} onChange={props.onChange} /> 
            <label className='decorative-checkbox-label' style={{...props.labelStyle, fontWeight:600}}>{props.label}</label>
        </div>
    )
}