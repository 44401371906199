import React, { useState } from 'react';
import './styles/facilities.css'


export const DisabledCheckbox = ({ template, item }) => {

    const [disabledMessageOpacity, setDisabledMessageOpacity] = useState(0);

    return (
        <div className='disabled-checkbox' onMouseOver={() => { setDisabledMessageOpacity(1) }} onMouseOut={() => { setDisabledMessageOpacity(0) }}>
            <input type="checkbox" className='checkbox' checked={false} style={{opacity:.5}} />
            <p style={{ ...template.styles.list, opacity: .25 }}>{(item.key === 'otherFac') ? "" : item.value}</p>
            <DisabledMessage disabledMessageOpacity={disabledMessageOpacity} />
        </div>
    )
}

export const DisabledMessage = ({ disabledMessageOpacity }) => {

    return (
        <div className='disabled-message' style={{opacity: disabledMessageOpacity}}>
            <p className='facility-hover-text' >{'Verisma does not process for this facility at this time. Please send your request directly to this location.'}</p>
        </div>
    )
}

export const TriLogoAndBanner = ({config, getwayApi, formId, imgPlacement}) => {
    return (
        <div id='logo-and-title-container'>
          <img className={imgPlacement[config.page.form.logo.position]} id='form-logo' src={`${getwayApi}/api/client/logo/${formId}`} alt={config.page.form.logo.alt} style={{ ...config.page.form.styles.logos.form, alignSelf: 'flex-start' }} />
          <div id='NYP-banner' style={{ background: config.page.form.styles.heading.color }}><h1 style={{ ...config.page.form.styles.heading, color: 'white' }} id={"tri-form-title"} > {config.page.form.heading.text} </h1></div>
        </div>
    )
}



