import React, { useEffect, useState, useReducer } from "react";
import DynamicForm from "./screens/form/DynamicForm";
import "react-datepicker/dist/react-datepicker.css";
import "../App.scss";
import { formReducer } from "../context/formReducer";
import {CaptchaContainer} from './screens/captcha/CaptchaContainer';
import {fetchFormData} from '../functions/apiCalls'
import {LoadingScreen} from './components/LoadingScreen'

const DynamicFormContainer = ({session}) => {
  
  const envt = window.location.origin;
  const [formInfo, dispatch] = useReducer(formReducer, null);
  const [loading, setLoading] = useState(false);
  const [formId, setFormId] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(envt === 'https://app-vra-test.azurewebsites.net' || envt === 'https://app-vra-dev.azurewebsites.net' || envt === 'http://localhost:3000' ? true : false);
  
  const [error, setError] = useState(null);
  const [esign, setEsign] = useState(true)
  
  // console.log(session)
  // console.log('form:', formInfo)
  
  // There will be two entry points into the form
  // 1. initial request which will need default values for the included form elements
  //    and some values may need to be populated if it is a 'known/authenticated' user request
  // 2. a 'back' request from the summary view (or subsequent adobe esign view) which will
  //    require all fields to be popluated from the persistence mechanism
  // Formik uses the initialValues object to populate the form controls and
  // validationSchema to determine what must be valid to have a valid form
  // TODO; decide how will you persist and reconstiture the data / merge it back into 'initialValues' attribute of the form for 'back' requests
  // NOTE; configure your axios url using match properties from react router to grab the session/form info
  // console.log('session:', session)
  
  // console.log(formInfo)
  useEffect(() => {
    fetchFormData(setError, setLoading, session, dispatch, setEsign, setFormId);
  }, [session]);

  return (
    <div id='page-container'>
      {loading && <LoadingScreen loadingMessage={"Loading the form. We'll be just a moment."}/>}
      {!isCaptchaValid && <CaptchaContainer setCaptcha={(e) => setIsCaptchaValid(e)} form={formInfo} formId={formId}/>}
      {isCaptchaValid && formInfo && <DynamicForm formInfo={formInfo} dispatch={dispatch} session={session.id} esign={esign} formId={formId}/>}
      {/* TODO; error UI */}
      {error && <div>TODO: Error Component {error}</div>}
    </div>
  );
};

export default DynamicFormContainer;
