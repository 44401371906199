import React from "react";
import { Field } from "formik";
import RadioButtonGroup from "../../../components/RadioButtonGroup";
import RadioButton from "../../../components/RadioButton";
import Required from "../../../components/Required";
import Popover from "../../../../formik/refactorOut/components/Popper/Popover";
import { popoverLocation } from "../../../../config/Configuration";

const PurposeOfDisclosure = ({ formik, inputClasses, template, config }) => {
  return (
    config.page.form.sections.purposeOfDisclosureSection.active ?
      <div>
        <div className="row">
          <div className="col">
            <h2 style={template.styles.section} className="section-heading">
              {template.title}
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <label>
                <span style={template.styles.label}>
                  {template.fields.disclosurePurposeGroup.label}
                </span>{" "}
                <Required
                  value={
                    template.fields.disclosurePurposeGroup.validations.required.value
                  }
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <RadioButtonGroup
                  id="disclosurePurposeGroup"
                  value={formik.values.disclosurePurposeGroup}
                  error={formik.errors.disclosurePurposeGroup}
                  touched={formik.touched.disclosurePurposeGroup}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                >
                  {template.fields.disclosurePurposeGroup.entries.map(option => (
                    <span
                      style={template.styles.list}
                      key={option.key}
                      className="form-check"
                    >
                      <Field
                        key={option.key}
                        component={RadioButton}
                        name="disclosurePurposeGroup"
                        id={option.key}
                        checked={option.checked}
                        value={option.key}
                        label={option.value}
                      />
                      <Popover placement={popoverLocation}>
                        <Popover.Target>
                          <i className="fa fa-info-circle fa_custom"></i>
                        </Popover.Target>
                        <Popover.Content>{option.tooltip}</Popover.Content>
                      </Popover>
                    </span>
                  ))}
                </RadioButtonGroup>
              </div>
              <div style={{ marginLeft: "2%" }} className="form-group col-4">
                <input
                  {...formik.getFieldProps("disclosurePurposeGroupOther")}
                  type="text"
                  maxLength={template.fields.disclosurePurposeGroupOther.validations.maxLength.value}
                  name="disclosurePurposeGroupOther"
                  id="disclosurePurposeGroupOther"
                  className="form-control"
                  placeholder={template.fields.disclosurePurposeGroupOther.hint}
                />
                {formik.touched.disclosurePurposeGroup &&
                  formik.errors.disclosurePurposeGroup ? (
                  <span className="input-error-text">
                    {formik.errors.disclosurePurposeGroup}
                  </span>
                ) : null}
                {formik.touched.disclosurePurposeGroupOther &&
                  formik.errors.disclosurePurposeGroupOther ? (
                  <span className="input-error-text">
                    {formik.errors.disclosurePurposeGroupOther}
                  </span>
                ) : null}
              </div>
              <div style={{ marginTop: 20 }}></div>
            </div>
          </div>
        </div>
      </div>
      :
      <></>
  );
};

export default PurposeOfDisclosure;
