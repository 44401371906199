import { Formik } from 'formik';
import React from 'react';
import './styles.scss'

const BaptistFormHeader = ({imgPlacement, config, getwayApi, formId}) => {
    return (
        <div id='logo-and-title-container'>
          <img className={imgPlacement[config.page.form.logo.position]} id='form-logo' src={`${getwayApi}/api/client/logo/${formId}`} alt={config.page.form.logo.alt} style={{ ...config.page.form.styles.logos.form }} />
          <h1 style={config.page.form.styles.heading} id={"form-title"} > {config.page.form.heading.text} </h1>
          {
            config.context.organization === '1007291' &&
            <>
            <h2 style={{...config.page.form.styles.heading, fontSize: '1.25em', marginTop:5}} className='baptist-subheader'>Attorney Requests ONLY</h2>
            <h2 style={{...config.page.form.styles.heading, fontSize: '1.25em', marginTop:5}} className='baptist-subheader'>(Subpoenas and Depositions will not be accepted via this form)</h2>
            </>
          }
        </div>
    )
}

export default BaptistFormHeader