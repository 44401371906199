import {SHOW_ERROR_MODAL} from './actionTypes';

export const showErrorModalAction = (errors) => {
  return {
    type: SHOW_ERROR_MODAL,
    payload: {
      errors,
      showErrorModal: true
    }
  }
}

