import * as Yup from "yup";

import {
  phoneRegex,
  zipRegex,
} from "./validationFunctions";

export const discloseToValidations = section => {
  if (section.active) {
    return Yup.object().shape({
      discloseToEntityGroup: Yup.string().required(
        section.fields.discloseToEntityGroup.validations.required.message
      ),
      discloseToName: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string().required(
          () =>
            section.fields.discloseToName.validations.required.value &&
            section.fields.discloseToName.validations.required.message
        )
      }),
      discloseToPhone: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string()
          .required(
            () =>
              section.fields.discloseToPhone.validations.required.value &&
              section.fields.discloseToPhone.validations.required.message
          )
          .max(
            section.fields.discloseToPhone.validations.maxLength
              .value,
              section.fields.discloseToPhone.validations.maxLength
              .message
          )
          .matches(
            phoneRegex,
            section.fields.discloseToPhone.validations.validPhone.message
          )
      }),
      discloseToFax: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string().matches(
          phoneRegex,
          section.fields.discloseToFax.validations.validPhone.message
        )
        .max(
          section.fields.discloseToFax.validations.maxLength
            .value,
            section.fields.discloseToFax.validations.maxLength
            .message
        )
      }),
      discloseToAddressLine1: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string()
          .required(
            () =>
              section.fields.discloseToAddressLine1.validations.required
                .value &&
              section.fields.discloseToAddressLine1.validations.required
                .message
          )
          .max(
            section.fields.discloseToAddressLine1.validations.maxLength
              .value,
            section.fields.discloseToAddressLine1.validations.maxLength
              .message
          )
      }),
      discloseToAddressLine2: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string()
          .required(
            () =>
              section.fields.discloseToAddressLine2.validations.required
                .value &&
              section.fields.discloseToAddressLine2.validations.required
                .message
          )
          .max(
            section.fields.discloseToAddressLine2.validations.maxLength
              .value,
            section.fields.discloseToAddressLine2.validations.maxLength
              .message
          )
      }),
      discloseToCity: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string()
          .required(
            () =>
              section.fields.discloseToCity.validations.required.value &&
              section.fields.discloseToCity.validations.required.message
          )
          .max(
            section.fields.discloseToCity.validations.maxLength.value,
            section.fields.discloseToCity.validations.maxLength.message
          )
      }),
      discloseToState: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string()
          .required(
            () => {
              // console.log(section)
             return section.fields.discloseToState.validations.required.value &&
              section.fields.discloseToState.validations.required.message
            }
          )
          .max(
            section.fields.discloseToState.validations.maxLength.value,
            section.fields.discloseToState.validations.maxLength.message
          )
      }),
      discloseToZip: Yup.string().when("discloseToEntityGroup", {
        is: "namedPersonEntity",
        then: Yup.string()
          .required(
            () =>
              section.fields.discloseToZip.validations.required.value &&
              section.fields.discloseToZip.validations.required.message
          )
          .matches(
            zipRegex,
            section.fields.discloseToZip.validations.validZipcode.message
          )
      })
    });
  }  
};



