import * as Yup from "yup";

export const additionalInfoValidations = section => {
    if(section.active){
        return Yup.object().shape( {    
           
            repDesc: Yup.string()
            .max(
                section.fields.repDesc.validations.maxLength.value,
                section.fields.repDesc.validations.maxLength.message
              )
            
          
    })}
}