import React, {useState} from 'react';
import './styles.scss'
import ErrorIcon from '../../assets/error-icon.png'
import PBVLogo from '../../assets/pbv.png'

const SubmissionErrorScreen = ({errorInfo, orgLogo, formSpec, failedSubmissionData, setFailedSubmissionData, setNextPage, setSubmissionError, formik, setShowSummary}) => {
    
    // const [failedSubmissionData, setFailedSubmissionData] = useState(null)
    console.log(errorInfo)
    
    return (
        <div id='submission-error-screen'>
            <img className='org-logo' src={orgLogo} alt="organization logo" style={{width: formSpec.page.form.styles.logos.form.width}} />
            <div id='submission-error-content'>
                <div id='submission-error-message'>
                    <img id='error-icon'src={ErrorIcon} alt="error" />
                    <h1>Something went wrong</h1>
                    <div>
                        <p>We’re sorry, but we ran into an issue submitting your request.</p>
                        <p>Our team is working to resolve the problem.</p>
                    </div>
                </div>
                <p id='se-error-code'>Error Code: {`${errorInfo.error.response.status}789-1`}</p>
                <button id='error-button' style={formSpec.page.form.styles.buttons.act} onClick={
                    (e)=>{
                        // set nextPage to false
                        // formik.setValues(failedSubmissionData)
                        failedSubmissionData && setFailedSubmissionData(failedSubmissionData)
                        setNextPage(false)
                        // set errorInfo to false
                        setSubmissionError(false)
                        setShowSummary(false)
                        // set the formik values to failedSubmissionData
                    }
                    }>Back to Form
                </button>
            </div>
            <img className='org-logo' src={PBVLogo} alt="Powered By Verisma" style={{width: 110, marginTop: 40}} />
        </div>
    )
}

export default SubmissionErrorScreen;