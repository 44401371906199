import React, {useEffect, useState, useCallback} from "react";
import Required from "../../../../components/Required";
import Checkbox from "../../../../components/Checkbox";
import { DisabledCheckbox } from '../../orgs/7042/components'
import { Field } from "formik";
import RadioButtonGroup from "../../../../components/RadioButtonGroup";
import RadioButton from "../../../../components/RadioButton";

const BaptistFacilitySection = ({ formik, inputClasses, props, template, config }) => {

  const [BHMGPPSelected, setBHMGPPSelected] = useState(false)
  
  const clearOtherField = () =>{
    setBHMGPPSelected(false); 
    formik.setFieldValue("otherFac", "");
    console.log(formik.values) 
  }
  
  const setOtherField = () =>{
    setBHMGPPSelected(true);
    // document.getElementsByClassName('custom-form-check')[30].blur()
    !formik.values.otherFac.length && document.getElementById('otherFac').focus()
    document.getElementById('otherFac').disabled = false
    console.log(formik.values) 
  }
  
  useEffect(()=>{
    console.log("facility:", formik.values.facilitiesGroup)
    if (!formik.values.facilitiesGroup.includes('CBDNetwork10')) {
      formik.setFieldValue("otherFac", "")
      document.getElementById('otherFac').disabled = true
    }
    console.log("values", formik.values)
  }, [formik.values.facilitiesGroup])
  
  useEffect(()=>{
    formik.values.facilitiesGroup.includes('CBDNetwork10') ? 
    setOtherField()
    : 
    console.log()
  }, [formik.values.facilitiesGroup])

  
  return (
    config.page.form.sections.authorizationToReleaseSection.active ?
      <div id='medicalFacilitiesRequested' className='form-section'>
        <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
        <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>

        <RadioButtonGroup id="rep-radio-options" value={formik.values.facilitiesGroup} error={formik.errors.facilitiesGroup} touched={formik.touched.facilitiesGroup} onBlur={formik.setFieldTouched} >
            <span className="rep-radio-options">
              {template.fields.facilitiesGroup.entries.map(option => {
                if (option.key !== 'otherFac' && option.key !== 'CBDNetwork10'){
                  return (
                      <Field 
                      config={config} 
                      key={option.value} 
                      component={RadioButton} 
                      name="facilitiesGroup" 
                      id={option.key} 
                      checked={formik.values.facilitiesGroup === [option.key] ? true : console.log(formik.values.facilitiesGroup)} 
                      value={option.key} 
                      label={option.value}
                      />
                  )  
                }
              } )}
            </span>
          </RadioButtonGroup>
        
        {/* <FacilityItem item={{key: 'CBDNetwork10', value: 'Baptist Health Medical Group Physician Practice'}} template={template} formik={formik} config={config} disabled={false} /> */}
        <Field config={config} key={"Baptist Health Medical Group Physician Practice"} component={RadioButton} name="facilitiesGroup" id={"CBDNetwork10"} checked={formik.values.facilitiesGroup === ["CBDNetwork10"] ? true : console.log(formik.values.facilitiesGroup)} value={"CBDNetwork10"} label={"Baptist Health Medical Group Physician Practice"} />
        <FacilityItem item={{key: 'otherFac', value: 'Indicate provider name or facility if requesting BHMG records'}} template={template} formik={formik} config={config} disabled={!BHMGPPSelected} key={54321}/>
      </div>
      :
      <></>
  );
};

export const FacilityItem = ({ item, template, formik, config, disabled }) => {

  return (
    <div key={item.key} className={`check-option tp-facility-item ${item.key === 'otherFac' && 'other-tp-facility-item'}`}>
      {!disabled && <Checkbox style={template.styles.list} className="custom-form-check checkbox" key={item.value} name="facilitiesGroup" label={(item.key === 'otherFac') ? "" : item.value} value={item.key} />}
      {disabled && <div style={{marginLeft:26}}></div>}
      {" "}
      {item.key === "otherFac" &&
        <div className="form-group" style={{ marginBottom: 0, alignSelf: 'flex-start', width: '100vw', position: 'relative', left: -27 }}>
          <input
            {...formik.getFieldProps("otherFac")}
            style={{ width: '40%' }}
            type="text"
            maxLength={template.fields.otherFac.validations.maxLength.value}
            name="otherFac"
            id="otherFac"
            className="form-control"
            style={config.page.form.styles.hint}
            placeholder={template.fields.otherFac.hint}
            disabled={disabled}
          />
          {formik.touched.otherFac &&
            formik.errors.otherFac ? (
            <span className="input-error-text">
              {formik.errors.otherFac}
            </span>
          ) : null}
        </div>
      }
    </div>
  )
}



export default BaptistFacilitySection;