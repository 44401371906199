import React from 'react';
import './styles.scss';

const BaptistFacilityInfo = () => {
    return (
        <div className="baptist-facility-info">
            <hr />
            <p>Please do not submit requests for <strong>billing</strong> or <strong>imaging/films</strong> with this form. Contact the respective departments to submit a request for billing records and/or films/images.</p>
            <div className='films-billing-container'>
                <div className='billing-section section'>
                    <p style={{ fontWeight: 700 }}>Billing Records:</p>
                    <p>If you are requesting a copy of billing records, you must contact <strong>Patient Financial Services</strong> at 786-596-6507.</p>
                    <p><strong>Itemized Billing</strong> requests must be sent to:</p>
                    <p style={{ fontWeight: 700 }}>
                        Baptist Health South Florida<br />
                        ATTN: Patient Financial Services<br />
                        PO Box 830880<br />
                        Miami, FL 33283
                    </p>
                </div>
                <div className='separator'></div>
                <div className='films-and-images-section section'>
                    <p style={{ fontWeight: 700 }}>Films/Images:</p>
                    <table className='billing-facility-table'>
                        <tr>
                            <th>Facility</th>
                            {/* <th>Department</th> */}
                            <th>Fax</th>
                            <th>Email or Phone</th>
                        </tr>
                        <tr>
                            <td>Baptist Hospital of Miami - BH Imaging Legal</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-533-9579</td>
                            <td><a href="mailto:BHImagingLegal@baptisthealth.net">BHImagingLegal@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>South Miami Hospital</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-533-9531</td>
                            <td><a href="mailto:SMHImagesRequests@baptisthealth.net">SMHImagesRequests@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>Doctors Hospital</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-533-9712</td>
                            <td><a href="mailto:DHImagesRequests@baptisthealth.net">DHImagesRequests@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>Homestead Hospital - HHImagingLegal</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-243-8562</td>
                            <td><a href="mailto:HHImagingLegal@baptisthealth.net">HHImagingLegal@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>West Kendall Baptist Hospital</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-533-9633</td>
                            <td><a href="mailto:DG-WKBHFilmRoom@baptisthealth.net">DG-WKBHFilmRoom@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>Mariners Hospital & Fisherman’s Hospital</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-260-0517</td>
                            <td><a href="mailto:DG-MHImagingStaff@baptisthealth.net">DG-MHImagingStaff@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>Baptist Outpatient Services</td>
                            {/* <td>Film Library</td> */}
                            <td className='film-fax'>786-596-3613</td>
                            <td><a href="mailto:BHImagingLegal@baptisthealth.net">BHImagingLegal@baptisthealth.net</a></td>
                        </tr>
                    </table>
                    <p style={{ fontWeight: 700, marginTop: 20 }}>Pathology Slides:</p>
                    <table className='billing-facility-table'>
                        <tr>
                            <th>Facility</th>
                            <th>Phone Number</th>
                            <th>Fax Number</th>
                            <th>Email or Phone</th>
                        </tr>
                        <tr>
                            <td>Baptist Hospital of Miami</td>
                            <td className='film-fax'>786-596-4166</td>
                            <td className='film-fax'></td>
                            <td>Refer to phone/fax number</td>
                        </tr>
                        <tr>
                            <td>Doctors Hospital</td>
                            <td className='film-fax'>786-308-3120</td>
                            <td className='film-fax'>786-533-9567</td>
                            <td><a href="mailto:DG-DHPathologyGroup@baptisthealth.net">DG-DHPathologyGroup@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>Homestead Hospital</td>
                            <td className='film-fax'>786-243-8519</td>
                            <td className='film-fax'>786-533-9891</td>
                            <td><a href="mailto:DG-BHSFPathologyOvation@baptisthealth.net">DG-BHSFPathologyOvation@baptisthealth.net</a></td>
                        </tr>
                        <tr>
                            <td>South Miami Hospital</td>
                            <td className='film-fax'>786-662-8168</td>
                            <td className='film-fax'>786-533-9434</td>
                            <td>Refer to phone/fax number</td>
                        </tr>
                        <tr>
                            <td>Bapitst Health Hospital Doral</td>
                            <td className='film-fax'>786-662-8168</td>
                            <td className='film-fax'>786-533-9434</td>
                            <td>Refer to phone/fax number</td>
                        </tr>

                    </table>
                </div>
            </div>
        </div>
    )
}

export default BaptistFacilityInfo;