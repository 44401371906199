import React from "react";
import './styles/facilities.css'
import Required from '../../../../components/Required'
import {FacilityItem} from '../../sections/AuthorizationToReleaseSection'

const NYPOutpatientFacilities = ({ formik, template, config, orgId }) => {

    let facGroup = formik.values.facilitiesGroup;
    let formSpecOutpatientGroup = template.fields.outPatientFacilitiesGroup

    const addValueToFormObj = (index, event) => {
        facGroup[0][index.opKey] = event.target.value;
    }

    facGroup.length === 0 && facGroup.push({ CUIMC: '', WCM: '', NMGB: '', NMGHV: '', NMGQ: '', NMGW: '' });

    return (
        
        <div id='medicalFacilitiesRequested' className='form-section'>
        <h2 style={template.styles.section} className="section-heading"> {template.title} </h2>
        <label className='non-textbox-label'> <span style={template.styles.label}> {template.fields.facilitiesGroup.label} </span> {" "} <Required value={template.fields.facilitiesGroup.validations.required.value} /> </label>

        <label className='non-textbox-label' style={{display: 'block'}}>NYP Hospitals</label>
        <div id='ordinary-facilities' className='form-fields' style={{flexFlow: 'column wrap', maxHeight:250, marginBottom: 10}}>
          {template.fields.facilitiesGroup.entries.map((item, index) => {
            return (
              (item.type === 'hospital') && (item.key !== 'otherFac') &&
              // Check to see if the above line is needed for Temple to work
              <FacilityItem item={item} template={template} formik={formik} config={config} disabled={item.disabled} key={index}/>
            )
          })
          }
          
        </div>
        
        <label className='non-textbox-label' style={{display: 'block'}}>NYP Medical Groups</label>
        <div id='ordinary-facilities' className='form-fields' style={{flexFlow: 'column wrap', maxHeight:250, marginBottom: 10}}>
          {template.fields.facilitiesGroup.entries.map((item, index) => {
            return (
              (item.type === 'medGroup') && (item.key !== 'otherFac') &&
              // Check to see if the above line is needed for Temple to work
              <FacilityItem item={item} template={template} formik={formik} config={config} disabled={item.disabled} key={index}/>
            )
          })
          }
          
        </div>
        <FacilityItem item={{key: 'otherFac', value: 'Specific facility or provider at CBC Trial Works'}} template={template} formik={formik} config={config} disabled={false} key={54321}/>
        
        <div id='1007042'>
            <p id='tp-op-label'>{formSpecOutpatientGroup.label}</p>
            <div id='tp-op-facilities' className='form-fields form-fields-column'>

                {formSpecOutpatientGroup.entries.map(item => {
                    return (
                        <div className='tp-op-field' key={item.key} style={{ opacity: item.disabled && .5 }}>
                            <p className='tp-op-field-label'>{item.value}</p>
                            {item.disabled && <input onBlur={(e) => { addValueToFormObj(item, e) }} type="text" disabled maxLength={100} name="otherFac" id="otherFac" className="form-control tripartite-outpatient-input" style={config.page.form.styles.hint} placeholder={item.placeholder} />}
                            {!item.disabled && <input onBlur={(e) => { addValueToFormObj(item, e) }} type="text" maxLength={100} name="otherFac" id="otherFac" className="form-control tripartite-outpatient-input" style={config.page.form.styles.hint} placeholder={item.placeholder} />}
                            {formik.touched.otherFac &&
                                formik.errors.otherFac ? (<span className="input-error-text"> {formik.errors.otherFac} </span>) : null}
                        </div>
                    )
                })}
            </div>
        </div>
    </div>

    );
};

export default NYPOutpatientFacilities;
